import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  index: number;
  value: number;
}

const AvailableReportsTabPanel = ({ children, value, index }: PropsWithChildren<Props>) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ mb: 1 }}
    >
      {value === index && <Box> {children} </Box>}
    </Box>
  );
};

export default AvailableReportsTabPanel;
