import { ExcelReport, ExcelReportParameter, FilterModelType, ReportSource } from "../../api/biApi.types";
import { Box, Button, Divider, Grid, Switch, Typography } from "@mui/material";
import { BackButtonDefault } from "../components/common/BackButton";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import React from "react";
import cloneDeep from "../../utils/cloneDeep";
import isEqual from "../../utils/isEqual";
import { allDataRangeParametersAreValid, allRequiredParametersSet } from "./utils/allRequiredParametersSet";
import ScrollableFlexContainer from "../components/common/ScrollableFlexContainer";
import FilterItem from "./FilterItem";
import SelectedColumnsContainer from "./SelectedColumnsContainer";
import { XEntriliaReportParameter } from "../../store/store.types";
interface Props {
  report: ExcelReport;
  originalFilters: XEntriliaReportParameter[];
  submitButtonText: string;
  exportWithParameters: boolean;
  clientCode: string;
  submitButtonDisabled?: boolean;
  selectedColumns: number[];
  onBack: () => void;
  onContinue: (filters: XEntriliaReportParameter[]) => void;
  onChangeExportWithParameters: (withParameters: boolean) => void;
  onColumnsSelected: (columns: number[]) => void;
}

export default function SetParametersStep({
  report,
  originalFilters,
  submitButtonText,
  exportWithParameters,
  clientCode,
  submitButtonDisabled,
  selectedColumns,
  onBack,
  onContinue,
  onChangeExportWithParameters,
  onColumnsSelected,
}: Props) {
  const [filters, setFilters] = React.useState<XEntriliaReportParameter[]>(cloneDeep(originalFilters));

  const onUpdateFilter = React.useCallback(
    (param: ExcelReportParameter, values: string[], equalityType?: FilterModelType) => {
      const mappedValuesWithNull = values.map((v) => (v === "" ? null : v));
      const copy = cloneDeep(filters);
      const item = copy.find((f) => f.id === param.id);
      const text = param.type === "option" ? param.options?.find((o) => o.value === values[0])?.text : undefined;

      if (item === undefined) {
        copy.push({
          id: param.id,
          name: param.name,
          values: cloneDeep(mappedValuesWithNull),
          text,
          equalityType: param.equalityType,
          description: param.description,
          reportSource: report.reportSource,
        });
      } else {
        if (isEqual(item.values, mappedValuesWithNull) && item.equalityType === equalityType) {
          return;
        }
        item.values = cloneDeep(mappedValuesWithNull);
        item.text = text;
        item.equalityType = equalityType;
      }
      setFilters(
        copy.sort(
          (a, b) =>
            report.parameters.findIndex((p) => p.id === a.id) - report.parameters.findIndex((p) => p.id === b.id)
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, setFilters, report.parameters]
  );

  const areRequiredParametersSet = React.useMemo(
    () => allRequiredParametersSet(report.parameters, filters),
    [report.parameters, filters]
  );

  const dateRangeParametersAreValid = React.useMemo(() => {
    return allDataRangeParametersAreValid(report.parameters, filters);
  }, [report.parameters, filters]);

  const getParamFilter = React.useCallback(
    (id: string) => {
      const item = filters.find((f) => f.id === id);
      if (item === undefined) return [];
      return item.values.map((v) => v ?? "");
    },
    [filters]
  );

  const getFilterEqualityType = React.useCallback(
    (id: string) => {
      const item = filters.find((f) => f.id === id);
      return item?.equalityType;
    },
    [filters]
  );

  const groupedParametersByGroup = React.useMemo(() => groupBy(report.parameters, "group"), [report.parameters]);
  const canDisplayParameters = report.reportSource === ReportSource.BI && report.parameters.length > 0;

  const isSubmitButtonDisabled = !areRequiredParametersSet || !dateRangeParametersAreValid || submitButtonDisabled;

  return (
    <Grid container sx={{ flex: 1, flexDirection: "column", pt: 2, pb: 1, px: 2.5, gap: 1 }}>
      <Grid>
        <BackButtonDefault onClick={onBack} />
      </Grid>
      <Grid sx={{ gap: 2 }}>
        <Typography variant="h6">{report.name}</Typography>
        <Divider sx={{ mt: 1.5 }} />
      </Grid>
      <ScrollableFlexContainer scrollContainerSx={{ pr: 0.5 }}>
        {groupedParametersByGroup &&
          Object.entries(groupedParametersByGroup).map(([key, parameters]) => (
            <Grid key={key} sx={{ display: "flex", flexDirection: "column", pt: 1, gap: 2 }}>
              <Typography variant="subtitle1">{key}</Typography>
              <Grid
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1fr",
                  alignItems: "center",
                  rowGap: 1,
                  columnGap: 1,
                }}
              >
                {parameters &&
                  parameters.map((p) => (
                    <FilterItem
                      key={p.id}
                      info={p}
                      filterValues={getParamFilter(p.id)}
                      onUpdateFilter={onUpdateFilter}
                      filters={filters}
                      equalityType={getFilterEqualityType(p.id)}
                      clientCode={clientCode}
                    />
                  ))}
              </Grid>
            </Grid>
          ))}
        {report.parameters.length > 0 && <Divider sx={{ borderStyle: "dashed", pt: 2 }} />}
        <SelectedColumnsContainer
          report={report}
          selectedColumns={selectedColumns}
          onColumnsSelected={onColumnsSelected}
        />
      </ScrollableFlexContainer>
      {canDisplayParameters && (
        <>
          <Divider />
          <Box sx={{ display: "flex", px: 1, alignItems: "center", justifyContent: "end" }}>
            <Typography variant="body2">Show Parameters</Typography>
            <Switch
              checked={exportWithParameters}
              size="small"
              onChange={(_, checked) => onChangeExportWithParameters(checked)}
            />
          </Box>
        </>
      )}

      <Divider />
      <Grid container sx={{ gap: 1, justifyContent: "end", px: 2 }}>
        <Button variant="text" color="secondary" size="small" onClick={onBack}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          endIcon={<EastRoundedIcon />}
          onClick={() => onContinue(filters)}
          disabled={isSubmitButtonDisabled}
        >
          {submitButtonText}
        </Button>
      </Grid>
    </Grid>
  );
}

function groupBy<T>(arr: T[], property: keyof T): Record<string | number | symbol, T[]> {
  const groups: Record<string | number | symbol, T[]> = {};
  for (const item of arr) {
    const key = item[property];
    if (!((key as keyof T) in groups)) {
      groups[key as keyof T] = [];
    }
    groups[key as keyof T].push(item);
  }
  return groups;
}
