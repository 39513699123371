import { Stack, Typography } from "@mui/material";

const NoAvailableCompanies = () => {
  return (
    <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
      <Typography variant="body1" color="text.secondary" fontSize={13}>
        No companies available
      </Typography>
    </Stack>
  );
};

export default NoAvailableCompanies;
