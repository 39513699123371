import { ExcelReportParameter, FilterModelType } from "../../api/biApi.types";
import { XEntriliaReportParameter } from "../../store/store.types";
import { FilterItemBoolean } from "./FilterItemBoolean";
import { FilterItemDate } from "./FilterItemDate";
import { FilterItemDateRange } from "./FilterItemDateRange";
import { FilterItemOption } from "./FilterItemOption";
import { FilterItemTable } from "./FilterItemTable";
import FilterItemTableBySubValues from "./FilterItemTableBySubValues";

interface FilterItemProps {
  info: ExcelReportParameter;
  filterValues: string[];
  filters: XEntriliaReportParameter[];
  equalityType?: FilterModelType;
  clientCode: string;
  onUpdateFilter: (param: ExcelReportParameter, values: string[], equality?: FilterModelType) => void;
}

const FilterItem = ({ info, filterValues, onUpdateFilter, filters, equalityType, clientCode }: FilterItemProps) => {
  if (info.type === "date") {
    return <FilterItemDate info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilter} />;
  }
  if (info.type === "daterange") {
    return <FilterItemDateRange info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilter} />;
  }
  if (info.type === "boolean") {
    return <FilterItemBoolean info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilter} />;
  }
  if (info.type === "table") {
    if (info.subValuesParameterNo !== undefined) {
      return (
        <FilterItemTableBySubValues
          info={info}
          filterValues={filterValues}
          onUpdateFilter={onUpdateFilter}
          filters={filters}
          clientCode={clientCode}
        />
      );
    }
    return (
      <FilterItemTable
        info={info}
        filterValues={filterValues}
        onUpdateFilter={onUpdateFilter}
        equalityType={equalityType}
        clientCode={clientCode}
      />
    );
  }
  if (info.type === "option") {
    return <FilterItemOption info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilter} />;
  }
  return <></>;
};

export default FilterItem;
