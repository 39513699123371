import React from "react";
import { redirectToExternalLoginSignin } from "./auth";
import InlineLoader from "../pages/components/inlineLoader/InlineLoader";

export default function AuthenticationRequest() {
  React.useEffect(() => {
    redirectToExternalLoginSignin();
  }, []);
  return <InlineLoader text={"Redirecting to the authenticatiion..."} />;
}
