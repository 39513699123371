import { getExcelDateFormatFromLocale } from "../../../utils/Utilities";
import { XWorksheetData } from "../hooks/useExcelDataApi.types";
import { columnsAutofit, loadColumnsWidth } from "./loadColumns";
import { loadImages } from "./loadImages";
import { loadRows, loadRowsHeight } from "./loadRows";
import mergeCells from "./mergeCells";

export default async function populateWorksheet(
  context: Excel.RequestContext,
  worksheetData: XWorksheetData,
  worksheetId: string
) {
  let eventBoolean = false;
  try {
    context.runtime.load("enableEvents");
    await context.sync();

    eventBoolean = context.runtime.enableEvents;
    context.runtime.enableEvents = !eventBoolean;

    const sheet = context.workbook.worksheets.getItem(worksheetId);
    context.application.suspendApiCalculationUntilNextSync();
    context.application.suspendScreenUpdatingUntilNextSync();

    const userDefaultDateFormat = getExcelDateFormatFromLocale(Office.context.contentLanguage);

    await mergeCells(context, sheet, worksheetData.mergeCells);
    await loadColumnsWidth(context, sheet, worksheetData.columns);
    await loadRowsHeight(context, sheet, worksheetData.rows);
    await loadRows(context, sheet, worksheetData.rows, userDefaultDateFormat);
    await columnsAutofit(context, sheet, worksheetData.columns);
    await loadImages(context, sheet, worksheetData.images);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    context.runtime.enableEvents = eventBoolean;
  }
}
