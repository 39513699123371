export const getParameterValue = (obj: unknown) => {
  return getValueByKey(obj, 0);
};

export const getParameterText = (obj: unknown) => {
  return getValueByKey(obj, 1);
};

function getValueByKey(obj: unknown, index: number) {
  if (typeof obj !== "object" || obj === null) {
    return "";
  }

  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return "";
  }

  const firstKey = keys[index];
  if (firstKey === undefined) {
    return "";
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (obj as { [key: string]: any })[firstKey];
}
