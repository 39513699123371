import { Collapse, Grid } from "@mui/material";
import ConditionBlock from "./ConditionBlock";
import { useSelector } from "react-redux";
import { selectClients } from "../../../store/userSlice";
import InfoBlock from "./info-block/InfoBlock";
import { XEntriliaReportDescriptor, XEntriliaReportParameter } from "../../../store/store.types";

interface Props {
  expanded: boolean;
  report: XEntriliaReportDescriptor;
  filters: XEntriliaReportParameter[];
  isReadonlyReport: boolean;
}

function FiltersPanel({ expanded, filters, report, isReadonlyReport }: Props) {
  const clients = useSelector(selectClients);
  const reportCompanyTitle = clients.find((client) => client.clientCode === report.clientCode)?.title ?? "";

  return (
    <Collapse in={expanded} sx={{ flexGrow: 1 }}>
      <Grid
        container
        sx={(theme) => ({
          p: 1.5,
          borderTop: "1px solid " + theme.palette.divider,
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          bgcolor: "#FAFAFA",
        })}
      >
        <InfoBlock report={report} companyTitle={reportCompanyTitle} isReadonlyReport={isReadonlyReport} />
        <ConditionBlock report={report} filters={filters} />
      </Grid>
    </Collapse>
  );
}

export default FiltersPanel;
