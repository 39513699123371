import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import SearchField from "../components/common/SearchField";
import { ExcelReport, ReportKind, ReportSource } from "../../api/biApi.types";
import React, { useEffect } from "react";
import ScrollableFlexContainer from "../components/common/ScrollableFlexContainer";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import AvailableReportGroup, { Group } from "./AvailableReportGroup";
import { groupReports } from "./availableReportsList.helper";
import AvailableReportsTabPanel from "./AvailableReportsTabPanel";
import AvailableReportItem from "./AvailableReportItem";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLastSelectedReportListTab,
  selectCompanyExpandGroupsState,
  UserInfoActions,
} from "../../store/userSlice";

interface Props {
  availableReports: ExcelReport[];
  loading: boolean;
  reportsLoaded: boolean;
  onReportSelected: (report: ExcelReport) => void;
}

export default function AvailableReportsList({ availableReports, onReportSelected, loading, reportsLoaded }: Props) {
  const dispatch = useDispatch();
  const tabValue = useSelector(selectLastSelectedReportListTab);
  const expandGroupState = useSelector(selectCompanyExpandGroupsState);

  const [filter, setFilter] = React.useState<string>();

  const filteredReports = React.useMemo(() => {
    if (!filter) {
      return availableReports;
    }
    return availableReports.filter(
      (ar) =>
        ar.name.toLowerCase().includes(filter.toLowerCase()) ||
        ar.code.toLowerCase().includes(filter.toLowerCase()) ||
        ar.reportType.toLowerCase().includes(filter.toLowerCase())
    );
  }, [availableReports, filter]);

  const groups = React.useMemo(() => {
    const result: Group[] = [];
    result.push({ type: "All", reportGroups: groupReports(filteredReports) });
    result.push({
      type: "Fund Operations",
      reportGroups: groupReports(
        filteredReports.filter((r) => r.reportSource === ReportSource.BC && r.reportKind === ReportKind.Report)
      ),
    });
    result.push({
      type: "SmartViews",
      reportGroups: groupReports(
        filteredReports.filter((r) => r.reportSource === ReportSource.BI && r.reportKind === ReportKind.Report)
      ),
    });

    result.push({
      type: "Master Data",
      reportGroups: [
        { name: "Master Data", reports: filteredReports.filter((r) => r.reportKind === ReportKind.MasterDataReport) },
      ],
    });

    return result;
  }, [filteredReports]);

  useEffect(() => {
    const allGroup = groups[0];
    if (allGroup && filter !== undefined) {
      // After filtering, groups which have no matched reports will be hidden
      allGroup.reportGroups.forEach((group) => {
        dispatch(UserInfoActions.setExpandGroupsState({ groupName: group.name, expanded: true }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, groups]);

  return (
    <>
      <Grid container sx={{ flexDirection: "column", gap: 1.5 }}>
        <SearchField placeholder="Search a report..." onSearch={setFilter} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => dispatch(UserInfoActions.setLastSelectedReportListTab(newValue))}
            aria-label="Reports"
          >
            {groups.map((g, i) => (
              <Tab key={i} label={g.type} {...a11yProps(i)} sx={{ py: 0, px: 2 }} />
            ))}
          </Tabs>
        </Box>
      </Grid>
      <ScrollableFlexContainer>
        {reportsLoaded && filteredReports.length === 0 ? (
          <Stack height={"100%"} alignItems={"center"} justifyContent={"center"}>
            <Typography variant="body1" color="text.secondary" fontSize={13}>
              No reports found
            </Typography>
          </Stack>
        ) : (
          <>
            {loading && <InlineLoader />}
            {!loading &&
              groups.map((g, i) => (
                <AvailableReportsTabPanel key={i} value={tabValue} index={i}>
                  {g.reportGroups.length === 1 && (
                    <Stack sx={{ gap: 0.8 }}>
                      {g.reportGroups[0]?.reports.map((ar) => (
                        <AvailableReportItem key={ar.code} report={ar} onReportSelected={onReportSelected} />
                      ))}
                    </Stack>
                  )}
                  {g.reportGroups.length > 1 &&
                    g.reportGroups.map((rg, i) => {
                      const expanded = expandGroupState[rg.name] === true;
                      return (
                        <AvailableReportGroup
                          key={i}
                          group={rg}
                          expanded={expanded}
                          onExpandChanged={(value) =>
                            dispatch(UserInfoActions.setExpandGroupsState({ groupName: rg.name, expanded: value }))
                          }
                          onReportSelected={onReportSelected}
                        />
                      );
                    })}
                </AvailableReportsTabPanel>
              ))}
          </>
        )}
      </ScrollableFlexContainer>
    </>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
