import { XWorksheetData } from "../pages/components/hooks/useExcelDataApi.types";

export interface ExcelReport {
  code: string;
  name: string;
  reportSource: ReportSource;
  reportKind: ReportKind;
  level: string;
  reportType: string;
  parameters: ExcelReportParameter[];
  columns: ExcelReportColumn[];
  selectedColumns: number[];
}

export interface ExcelReportColumn {
  id: number;
  name: string;
}

export enum ReportSource {
  BC = "BC",
  BI = "BI",
}

export enum ReportKind {
  Report = "Report",
  MasterDataReport = "MasterDataReport",
}

export interface ExcelReportParameter {
  id: string;
  name: string;
  type: "date" | "daterange" | "table" | "boolean" | "option";
  required: boolean;
  description: string;
  group: string;
  options?: ExcelParameterOption[];
  reportSource: ReportSource;
  defaultValues?: string[];
  equalityType: FilterModelType;
  subValuesParameterNo?: string;
}

export interface ExcelParameterOption {
  value: string;
  text: string;
}

export interface RequestReportResponse {
  reportExportCode: string;
}

export interface ExportedReportStatusResponse {
  status?: BcReportExportStatus;
}
export interface ExportedReportDataResponse {
  data?: XWorksheetData;
}

export interface BcReportExportStatus {
  completed: boolean;
  error: boolean;
  errorText?: string;
}

export type BcParameterDictionary = { [key: string]: string } & {
  subValues?: string[];
};

export interface ParameterDictionaryResponse {
  dictionaryItems: {
    value: string | null;
    text: string;
    subValues?: string[];
  }[];
  fieldValueName: string;
  fieldTextName: string;
}

export enum FilterModelType {
  Equal = "Equal",
  NotEqual = "NotEqual",
}

export interface UserPermissionsInfo {
  userId: string;
  userName: string;
  permissions: UserPermissionSet[];
}

export interface UserPermissionSet {
  clientId: string;
  clientTitle: string;
  clientCode: string;
  permissions: AccessPermission[];
}

export interface ClientInfo {
  title: string;
  clientCode: string;
  branding: ClientBranding;
}
export interface ClientBranding {
  logoTypeUrl?: string;
  logoMarkUrl?: string;
}
export interface ClientsResponse {
  clients: ClientInfo[];
}

export interface UserData extends UserPermissionsInfo {
  clients: ClientInfo[];
}

export type AccessPermission =
  | "ManageOrganizationClients"
  | "ViewOrganizationUsers"
  | "ManageOrganizationUsers"
  | "ViewMessages"
  | "ViewInvestorSettings"
  | "ManageInvestorSettings"
  | "ManageClientIntegrations"
  | "ViewClientIntegrations"
  | "ManageReports"
  | "ViewReports"
  | "ViewInvestorPortalOnBehalfOfInvestor"
  | "ViewInvestorPortalSettings"
  | "ManageInvestorPortalSettings"
  | "ViewDocumentCollections"
  | "ViewFundOps"
  | "ViewFundStructure"
  | "ManageFundStructure"
  | "UseAiAssistant"
  | "ManageOrganizationReports"
  | "ManageCompanySettings"
  | "ViewCompanySettings"
  | "ViewCommunityPortalSettings"
  | "ManageCommunityPortalSettings"
  | "ManageOrganizationReportTemplates"
  | "ViewExpenses"
  | "ManageExpenses"
  | "ViewPortfolioMonitoring"
  | "ManagePortfolioMonitoring"
  | "ViewAllCompanyFiles"
  | "ManageAllCompanyFiles"
  | "ViewInvestorRelationsFiles"
  | "ManageInvestorRelationsFiles"
  | "ViewExpenseManagementFiles"
  | "ManageExpenseManagementFiles"
  | "ViewPortfolioMonitoringFiles"
  | "ManagePortfolioMonitoringFiles"
  | "ViewInvestorRelationsNotes"
  | "ManageInvestorRelationsNotes"
  | "ViewExpenseManagementNotes"
  | "ManageExpenseManagementNotes"
  | "ViewPortfolioMonitoringNotes"
  | "ManagePortfolioMonitoringNotes"
  | "ViewInvestorRelationsDataImports"
  | "ManageInvestorRelationsDataImports"
  | "ViewPortfolioMonitoringDataImports"
  | "ManagePortfolioMonitoringDataImports"
  | "ViewExpenseManagementDataImports"
  | "ManageExpenseManagementDataImports"
  | "ViewInvestorRequests"
  | "ViewInvestorRequestsNotes"
  | "ManageInvestorRequestsNotes";
