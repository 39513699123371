import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTrigger, updateReports } from "../../../store/reportsSlice";
import { generateReportId, getStoredReportSettings, storeReportSettings } from "../utils/storedReportSettings";
import useActiveWorksheet from "./useWorksheet";
import { logError } from "../../../logging";
import { debounceTime, Subject } from "rxjs";
import getShortUniqueId from "../utils/getShortUniqueId";
import { XEntriliaReportPrefix } from "./useEntriliaReportsMonitoring.types";
import { XEntriliaReportDescriptor } from "../../../store/store.types";

export default function useEntriliaReportsMonitoring() {
  const dispatch = useDispatch();
  const trigger = useSelector(selectTrigger);
  const refreshReportSubject = useMemo(() => new Subject(), []);

  useActiveWorksheet();

  const reloadReports = React.useCallback(async () => {
    refreshReportSubject.next(getShortUniqueId());
  }, [refreshReportSubject]);

  useEffect(() => {
    const subscription = refreshReportSubject.pipe(debounceTime(50)).subscribe(loadEntriliaReports);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshReportSubject]);

  useEffect(() => {
    reloadReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const subscribeOnWorksheetChanges = React.useCallback(async () => {
    const subscriptions: OfficeExtension.EventHandlerResult<unknown>[] = [];
    await Excel.run(async (context) => {
      subscriptions.push(context.workbook.worksheets.onDeleted.add(reloadReports));
      subscriptions.push(context.workbook.worksheets.onNameChanged.add(reloadReports));
      subscriptions.push(context.workbook.worksheets.onAdded.add(reloadReports));
    });

    return subscriptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const subscriptions: OfficeExtension.EventHandlerResult<unknown>[] = [];
    const subscribe = async () => {
      const sbs = await subscribeOnWorksheetChanges();
      sbs.forEach((s) => subscriptions.push(s));
    };
    subscribe();
    return () => subscriptions.forEach((s) => s.remove());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEntriliaReports = React.useCallback(async () => {
    await Excel.run(async (context) => {
      try {
        let reportsRanges = await getEntriliaReportsRangeData(context);

        reportsRanges = await updateDuplicatedReportRanges(context, reportsRanges);
        await removeDeletedReports(context, reportsRanges);
        const storedReportSettings = await getStoredReportSettings(context);

        const entriliaReports: XEntriliaReportDescriptor[] = [];
        for (let i = 0; i < reportsRanges.length; i++) {
          try {
            const item = reportsRanges[i];
            if (item === undefined) continue;
            const relevantReportSettings = storedReportSettings.find((s) => s.id === item.name);
            if (relevantReportSettings === undefined) continue;
            entriliaReports.push({ ...relevantReportSettings, worksheetName: item.worksheetName });
          } catch (ex) {
            logError(ex, "reportsToAdd");
          }
        }

        dispatch(updateReports(entriliaReports));
      } catch (ex) {
        logError(ex, "loadEntriliaReports");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

async function getEntriliaReportsRangeData(context: Excel.RequestContext): Promise<EntriliaReportData[]> {
  /**  check each worksheet for named ranges starting with XEntriliaReport_*/
  try {
    context.workbook.worksheets.load("items");
    await context.sync();
    context.workbook.worksheets.items.forEach((item) => context.load(item, "names/items,names/items/name"));
    await context.sync();

    const itemsToLoad: { nameItem: Excel.NamedItem; worksheet: Excel.Worksheet }[] = [];

    for (let i = 0; i < context.workbook.worksheets.items.length; i++) {
      const worksheet = context.workbook.worksheets.items.at(i);
      if (worksheet === undefined) continue;

      const names = worksheet.names.items;
      if (names === undefined) continue;
      for (let nameIndex = 0; nameIndex < names.length; nameIndex++) {
        const nameItem = names[nameIndex];
        if (nameItem?.name === undefined) continue;
        if (nameItem.name.startsWith(XEntriliaReportPrefix)) {
          context.load(worksheet, ["id", "name"]);
          itemsToLoad.push({ nameItem, worksheet });
        }
      }
    }

    await context.sync();
    return itemsToLoad.map((item) => ({
      id: item.nameItem.name,
      name: item.nameItem.name,
      worksheetId: item.worksheet.id,
      worksheetName: item.worksheet.name,
    }));
  } catch (e) {
    logError(e, "getEntriliaReportsRangeData");
    throw e;
  }
}

async function removeDeletedReports(context: Excel.RequestContext, namedRanges: EntriliaReportData[]) {
  try {
    const currentReportSettings = await getStoredReportSettings(context);
    if (currentReportSettings.length !== namedRanges.length) {
      const relevantReportSettings = currentReportSettings.filter((s) => namedRanges.some((n) => n.name === s.id));
      if (relevantReportSettings.length !== currentReportSettings.length) {
        await storeReportSettings(context, relevantReportSettings);
      }
    }
  } catch (e) {
    logError(e, "removeDeletedReports");
  }
}

async function updateDuplicatedReportRanges(context: Excel.RequestContext, reportsData: EntriliaReportData[]) {
  try {
    const storedReportSettings = await getStoredReportSettings(context);
    const duplicatedReportSettings: XEntriliaReportDescriptor[] = [];
    const renewedReportNamedRanges: EntriliaReportData[] = [];
    for (let i = 0; i < reportsData.length; i++) {
      const element = reportsData[i];
      if (element === undefined) continue;

      const worksheet = context.workbook.worksheets.items.find((item) => item.id === element.worksheetId);
      if (worksheet === undefined) continue;

      const storedReport = storedReportSettings.find((s) => s.id === element.name);
      if (storedReport !== undefined && isDuplicatedReport(storedReport, worksheet)) {
        const newReportSettings = createNewReportSettingsForDuplicatedReport(storedReport, worksheet);
        duplicatedReportSettings.push(newReportSettings);

        handleDuplicatedReportNamedRange(worksheet, storedReport, newReportSettings);

        renewedReportNamedRanges.push({
          id: newReportSettings.id,
          name: newReportSettings.id,
          worksheetId: newReportSettings.worksheetId,
          worksheetName: newReportSettings.worksheetName,
        });
      } else {
        renewedReportNamedRanges.push(element);
      }
    }
    if (duplicatedReportSettings.length > 0) {
      await storeReportSettings(context, [...storedReportSettings, ...duplicatedReportSettings]);
    }

    return renewedReportNamedRanges;
  } catch (e) {
    logError(e, "updateDuplicatedReportRanges");
    return reportsData;
  }
}

function createNewReportSettingsForDuplicatedReport(
  originalReportSettings: XEntriliaReportDescriptor,
  worksheet: Excel.Worksheet
) {
  const id = generateReportId();
  const settings: XEntriliaReportDescriptor = {
    ...originalReportSettings,
    id: id,
    worksheetId: worksheet.id,
    worksheetName: worksheet.name,
  };
  return settings;
}

function handleDuplicatedReportNamedRange(
  worksheet: Excel.Worksheet,
  storedReport: XEntriliaReportDescriptor,
  newReportSettings: XEntriliaReportDescriptor
) {
  try {
    const namedItem = worksheet.names.items.find((i) => i.name === storedReport.id);
    if (namedItem !== undefined) {
      namedItem.delete();
    }

    const namedRange = worksheet.getRange(storedReport.address);
    worksheet.names.add(newReportSettings.id, namedRange);
  } catch (e) {
    logError(e, "handleDuplicatedReportNamedRange");
  }
}

function isDuplicatedReport(storedReport: XEntriliaReportDescriptor, worksheet: Excel.Worksheet) {
  return storedReport.worksheetName !== worksheet.name && storedReport.worksheetId !== worksheet.id;
}

type EntriliaReportData = {
  id: string;
  name: string;
  worksheetId: string;
  worksheetName: string;
};
