import { SvgIcon, SvgIconProps } from "@mui/material";

export default function NoAccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 48, height: 58 }} viewBox="0 0 66 65" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 28.4311V49.6734C6.01475 49.9726 7.4618 50.365 9.24981 50.7584C12.9864 51.5807 18.176 52.4 24 52.4C29.7729 52.4 34.9626 51.581 38.7143 50.7581C40.5154 50.363 41.9761 49.969 43 49.669V28.4266C41.9853 28.1274 40.5382 27.7351 38.7502 27.3416C35.0136 26.5193 29.824 25.7 24 25.7C18.2271 25.7 13.0374 26.519 9.28565 27.342C7.48461 27.737 6.02392 28.131 5 28.4311ZM45.5 26.6L46.3503 24.2491L48 24.8458V53.2606L46.3424 53.8538L45.5 51.5C46.3424 53.8538 46.3419 53.854 46.3414 53.8542L46.337 53.8557L46.3281 53.8589L46.2998 53.8689C46.2764 53.8771 46.2439 53.8884 46.2024 53.9026C46.1195 53.9311 46.0008 53.9713 45.8481 54.0214C45.5428 54.1216 45.1011 54.2619 44.5369 54.4287C43.409 54.7621 41.7884 55.2026 39.7857 55.642C35.7874 56.519 30.2271 57.4 24 57.4C17.724 57.4 12.1636 56.5193 8.17519 55.6416C6.17766 55.202 4.56545 54.7612 3.44426 54.4273C2.88343 54.2602 2.44476 54.1198 2.14143 54.0193C1.98974 53.969 1.87183 53.9288 1.78934 53.9002C1.74809 53.8859 1.71569 53.8745 1.69234 53.8662L1.66414 53.8562L1.65071 53.8513C1.65017 53.8511 1.64966 53.851 2.5 51.5L1.64966 53.851L0 53.2543V24.8395L1.65759 24.2462L2.5 26.6C1.65759 24.2462 1.65809 24.246 1.65862 24.2458L1.66302 24.2443L1.67195 24.2411L1.70017 24.2312C1.72357 24.223 1.75612 24.2116 1.79759 24.1974C1.88053 24.1689 1.9992 24.1288 2.15189 24.0786C2.45723 23.9784 2.89888 23.8382 3.46307 23.6713C4.59098 23.3379 6.21155 22.8974 8.21435 22.4581C12.2126 21.581 17.7729 20.7 24 20.7C30.276 20.7 35.8364 21.5807 39.8248 22.4584C41.8223 22.898 43.4346 23.3389 44.5557 23.6728C45.1166 23.8398 45.5552 23.9802 45.8586 24.0807C46.0103 24.131 46.1282 24.1712 46.2107 24.1998C46.2519 24.2142 46.2843 24.2255 46.3077 24.2338L46.3359 24.2439L46.3448 24.2471L46.3493 24.2487C46.3498 24.2489 46.3503 24.2491 45.5 26.6Z"
        fill="#C7DBE5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9999 5.59998C18.1806 5.59998 13.3999 10.3807 13.3999 16.2V22.7H8.3999V16.2C8.3999 7.61926 15.4192 0.599976 23.9999 0.599976C32.5806 0.599976 39.5999 7.61926 39.5999 16.2V22.7H34.5999V16.2C34.5999 10.3807 29.8192 5.59998 23.9999 5.59998Z"
        fill="#C7DBE5"
      />
      <path
        d="M29.4001 35.9C29.4001 38.9 27.0001 41.3 24.0001 41.3C21.0001 41.3 18.6001 38.9 18.6001 35.9C18.6001 32.9 21.0001 30.5 24.0001 30.5C27.0001 30.5 29.4001 33 29.4001 35.9Z"
        fill="#00A866"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.5 48.2V36.5H26.5V48.2H21.5Z" fill="#00A866" />
    </SvgIcon>
  );
}
