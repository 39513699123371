import React from "react";
import { isAuthTokenExist } from "./auth";
import { useDispatch, useSelector } from "react-redux";
import { UserInfoActions, selectUserDataLoaded, selectUserInfo } from "../store/userSlice";
import { BiApi } from "../api/biApi";
import axios from "axios";

export default function usePermissions() {
  const dispatch = useDispatch();
  const userDataLoaded = useSelector(selectUserDataLoaded);
  const userInfo = useSelector(selectUserInfo);
  const [loadingUserData, setLoadingUserData] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [unauthorizedError, setUnauthorizedError] = React.useState<string>();
  const isAuthInProgress = React.useRef(false);

  const loadPermissions = React.useCallback(async () => {
    try {
      setLoadingUserData(true);
      const result = await BiApi.getUserData();

      if (result === undefined) return;
      if (result.success && result.data !== undefined) {
        dispatch(UserInfoActions.updateUserData(result.data));
      } else {
        setError(result.error?.message || `${result.error?.code}` || "Unknown error");
      }
    } catch (ex: unknown) {
      const errorMessage = (ex as Error).message || "Unknown error";
      if (isUnauthorizedError(ex)) {
        setUnauthorizedError(errorMessage);
      } else {
        setError(errorMessage);
      }
    } finally {
      setLoadingUserData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (userDataLoaded || isAuthInProgress.current) return;
    if (isAuthTokenExist()) {
      isAuthInProgress.current = true;
      loadPermissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, userDataLoaded]);

  return { loadingUserData, userDataLoaded, error, unauthorizedError };
}

function isUnauthorizedError(ex: unknown) {
  return axios.isAxiosError(ex) && ex.response?.status === 401;
}
