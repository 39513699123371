import { SvgIcon, SvgIconProps } from "@mui/material";

export default function FileIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 49, height: 61 }} viewBox="0 0 49 61" {...props}>
      <path
        d="M48.6629 60.225H0.462891V0.724976H36.3629L48.6629 13.025V60.225ZM5.46289 55.225H43.5629V15.125L34.2629 5.82498H5.46289V55.225Z"
        fill="#C7DBE5"
      />
      <path d="M46.163 18.325H31.563V3.22498H36.563V13.325H46.163V18.325Z" fill="#C7DBE5" />
      <path d="M37.4631 26.1254H11.6631V31.1254H37.4631V26.1254Z" fill="#C7DBE5" />
      <path d="M28 37.0004H12V42.0004H28V37.0004Z" fill="#C7DBE5" />
    </SvgIcon>
  );
}
