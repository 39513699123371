import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectParameter, setParameter } from "../../../store/parametersSlice";
import useRequest from "../../../hooks/useRequest";
import { BiApi } from "../../../api/biApi";
import { BcParameterDictionary, ReportSource } from "../../../api/biApi.types";
import { RootState } from "../../../store/store.types";

export function useParameterValues(reportType: ReportSource, name: string, clientCode: string) {
  const dispatch = useDispatch();
  const storedValue = useSelector((state: RootState) => selectParameter(state, name));
  const requestData = useRequest(() => BiApi.getParameterDictionary(reportType, name, clientCode));

  React.useEffect(() => {
    if (
      requestData.data === undefined &&
      requestData.error === undefined &&
      requestData.isLoading === false &&
      storedValue?.values === undefined
    ) {
      requestData.request();
    }
  }, [name, storedValue, requestData]);

  React.useEffect(() => {
    if (requestData.data !== undefined) {
      const fieldValueName = requestData.data.fieldValueName;
      const fieldTextName = requestData.data.fieldTextName;
      dispatch(
        setParameter({
          name,
          values: requestData.data.dictionaryItems.map(
            (item) =>
              ({
                [fieldValueName]: item.value ?? "",
                [fieldTextName]: item.text,
                subValues: item.subValues,
              }) as BcParameterDictionary
          ),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData.data]);

  return {
    value: storedValue,
    loaded: storedValue?.values !== undefined,
    loading: requestData.isLoading,
    error: requestData.error,
  };
}

export type ParameterValuesType = ReturnType<typeof useParameterValues>;
