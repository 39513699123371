import { XImageShape } from "../hooks/useExcelDataApi.types";

const maxSizeMb = 3;

export async function loadImages(context: Excel.RequestContext, sheet: Excel.Worksheet, images: XImageShape[]) {
  const rangesMap = new Map<string, RangeData>();
  for (let i = 0; i < images.length; i++) {
    const imageData = images[i];
    if (!imageData) return;
    loadRangeAreaForImage(imageData, sheet, rangesMap);
  }

  await context.sync();

  let accumulatedSizeMb = 0;

  for (let i = 0; i < images.length; i++) {
    const imageData = images[i];
    if (!imageData) continue;

    if (isPayloadSizeExceeded(accumulatedSizeMb, imageData.sizeMb)) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      accumulatedSizeMb = 0;
    }

    setupImage(imageData, rangesMap, sheet);
    accumulatedSizeMb += imageData.sizeMb;
  }

  if (accumulatedSizeMb > 0) {
    await context.sync();
  }
}

function isPayloadSizeExceeded(accumulatedSize: number, currentImageSize: number) {
  return accumulatedSize + currentImageSize > maxSizeMb;
}

function setupImage(imageData: XImageShape, rangesMap: Map<string, RangeData>, sheet: Excel.Worksheet) {
  const rangeFromMap = rangesMap.get(imageData.name);
  if (!rangeFromMap) return;
  const totalHeight = rangeFromMap.entireRange.height;
  const totalWidth = rangeFromMap.entireRange.width;
  const shape = sheet.shapes.addImage(imageData.imageBase64);

  shape.top = rangeFromMap.entireRange.top + imageData.from.rowOff;
  shape.left = rangeFromMap.entireRange.left + imageData.from.colOff;
  shape.width = Math.min(
    totalWidth,
    totalWidth - rangeFromMap.bottomRightRange.width + imageData.to.colOff - imageData.from.colOff
  );
  shape.height = Math.min(
    totalHeight,
    totalHeight - rangeFromMap.bottomRightRange.height + imageData.to.rowOff - imageData.from.rowOff
  );
  shape.name = `${IMAGE_NAME_PREFIX}_${imageData.name}`;
}

function loadRangeAreaForImage(imageData: XImageShape, sheet: Excel.Worksheet, rangesMap: Map<string, RangeData>) {
  if (!imageData) return;
  const entireRange = sheet.getRange(imageData.from.address + ":" + imageData.to.address);
  const bottomRightRange = sheet.getRange(imageData.to.address);
  entireRange.load("top,left,height,width");
  bottomRightRange.load("top,left,height,width");
  rangesMap.set(imageData.name, { entireRange, bottomRightRange });
}

export const IMAGE_NAME_PREFIX = "Entrilia";
type RangeData = { entireRange: Excel.Range; bottomRightRange: Excel.Range };
