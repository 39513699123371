import { useAuthentication } from "../../auth/useAuthentication";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import { Navigate } from "react-router-dom";
import { PageNames } from "../../types";

export default function ProcessSigninPage() {
  const { authenticating, authenticated, authenticatingError, refreshTokenError } = useAuthentication();

  if (authenticatingError !== undefined) {
    return <Navigate to={`/${PageNames.Error}`} />;
  }

  if (refreshTokenError !== undefined) {
    return <Navigate to={`/${PageNames.SignIn}`} />;
  }

  if (!authenticated || authenticating) {
    return <InlineLoader text="Authorization..." />;
  }

  return <Navigate to={"/"} />;
}
