import { BcParameterDictionary, FilterModelType } from "../../../api/biApi.types";

export const getHeaderValues = (values: BcParameterDictionary[] | undefined) => {
  const keys = Object.keys(values?.[0] || {});
  const valueHeaderName = keys[0] || "Value";
  const textHeaderName = keys[1] || "Description";
  return { valueHeaderName, textHeaderName };
};

export const getEquityType = (exclude: boolean) => {
  return exclude ? FilterModelType.NotEqual : FilterModelType.Equal;
};

export const getBooleanEquityType = (type: FilterModelType) => {
  return type.toString() === FilterModelType[FilterModelType.NotEqual];
};
