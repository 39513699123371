import { Stack, Typography } from "@mui/material";
import UserAvatar from "./UserAvatar";

interface Props {
  userName: string;
  avatarImage?: string;
}

export default function UserInfoMenuItem({ userName, avatarImage }: Props) {
  return (
    <Stack m={1} direction="row">
      <UserAvatar userName={userName} avatarImage={avatarImage} />
      <Stack>
        <Typography variant="subtitle2" color="primary">
          {userName}
        </Typography>
        <Typography variant="body2" color="secondary">
          Admin
        </Typography>
      </Stack>
    </Stack>
  );
}
