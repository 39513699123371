import { XRow } from "../hooks/useExcelDataApi.types";
import { setBorder, setCommonFormatOptions, setFill, setFont, setNumberFormat } from "./cellFormatHelper";

/**

Max Allowed Payload Size (symbol length): 4200000 symbols
Server part that throws exception:
EwaDSopt.agave.js =>
this.$.xa.ExcelRichApiMaxAllowedRequestPayloadSize

Client part that generates payload:
excel-web-16.00.js =>
t.prototype.buildRequestMessageBodyAndRequestFlags = function()

Load Rows handling (case #1):
    cells: 3760
    api calls: 3760 * 2 = 7520
    total payload: 4436091
    ONE ACTION payload: 4436091 / 7520 = 589
    ONE CELL payload: 589 * 2 = 1179

    expected cells to be handled in one batch: 4200000 / 1179 = 3562 - 20% = 2849

Load Rows handling (case #2):
    cells: 17394
    api calls: 17394 * 2 = 34788
    total payload: 18789178
    ONE ACTION payload: 18789178 / 34788 = 540
    ONE CELL payload: 540 * 2 = 1080

    expected cells to be handled in one batch: 4200000 / 1080 = 3562 - 20% = 3111 */
export async function loadRows(
  context: Excel.RequestContext,
  sheet: Excel.Worksheet,
  rows: XRow[],
  defaultDateFormat: string
) {
  context.application.suspendApiCalculationUntilNextSync();
  context.application.suspendScreenUpdatingUntilNextSync();

  let hasSynced = true;
  let totalCellIndex = 0;
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const rowData = rows[rowIndex];
    if (rowData === undefined) continue;

    for (let cellIndex = 0; cellIndex < rowData.cells.length; cellIndex++) {
      const cellData = rowData.cells[cellIndex];

      if (cellData?.reference === undefined) {
        continue;
      }

      totalCellIndex++;
      hasSynced = false;

      const cell = sheet.getRange(cellData.reference);
      //Number Formatting MUST be set before actual value
      setNumberFormat(cell, cellData, defaultDateFormat);
      if (cellData.value !== undefined) {
        cell.values = [[cellData.value]];
      }

      if (cellData.formula !== undefined && cellData.formula !== "") {
        cell.formulas = [[`=${cellData.formula}`]];
      }

      // see function comment to understand why we need to sync context every 2849 cells
      if (totalCellIndex > 0 && totalCellIndex % 2849 === 0) {
        // eslint-disable-next-line office-addins/no-context-sync-in-loop
        await context.sync();
        hasSynced = true;
        context.application.suspendApiCalculationUntilNextSync();
        context.application.suspendScreenUpdatingUntilNextSync();
      }

      if (!cellData.format) {
        continue;
      }

      const matchupScoreProps: Excel.SettableCellProperties = { format: {} };

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setCommonFormatOptions(matchupScoreProps.format!, cellData.format);
      setBorder(matchupScoreProps.format, cellData.format.borders);
      setFont(matchupScoreProps.format, cellData.format.font);
      setFill(matchupScoreProps.format, cellData.format.fill);

      cell.setCellProperties([[matchupScoreProps]]);
    }
  }

  if (!hasSynced) {
    await context.sync();
  }
}

/*
Max Allowed Payload Size (symbol length): 4200000 symbols


Row height handling example:
    rows: 11970
    api calls: 11970 * 3 = 35910
    total payload: 11657201
    ONE ACTION payload: 11657201 / 35910 = 325
    ONE ROW payload: 325 * 3 = 975

    expected rows to be handled in one batch: 4200000 / 975 = 4307 - 20% = 3446
*/
export async function loadRowsHeight(context: Excel.RequestContext, sheet: Excel.Worksheet, rows: XRow[]) {
  let hasSynced = true;
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    if (row === undefined) {
      continue;
    }
    hasSynced = false;
    const range = sheet.getRangeByIndexes(index, 0, 1, 1).getEntireRow();
    if (row.bestFit) {
      range.format.autofitRows();
    } else if (row.height !== undefined) {
      range.format.rowHeight = row.height;
    }
    // see function comment to understand why we sync context every 3446 rows
    if (index > 0 && index % 3446 === 0) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      context.application.suspendApiCalculationUntilNextSync();
      context.application.suspendScreenUpdatingUntilNextSync();
      hasSynced = true;
    }
  }

  if (!hasSynced) {
    await context.sync();
  }
}
