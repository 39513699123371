import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import WorkbookReportItem from "./WorkbookReportItem";
import { selectActiveWorksheetId } from "../../store/workbookSlice";
import { selectReports } from "../../store/reportsSlice";

export default function WorkbookReports() {
  const reportDescriptors = useSelector(selectReports);
  const activateWorksheetId = useSelector(selectActiveWorksheetId);

  return (
    <Grid container sx={{ flexDirection: "column", gap: 1 }}>
      {reportDescriptors.map((report) => (
        <WorkbookReportItem key={report.id} report={report} activeWorksheet={activateWorksheetId} />
      ))}
    </Grid>
  );
}
