import { Grid, Typography, Divider, Button, Switch } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { cloneDeep } from "lodash";
import React from "react";
import { BcParameterDictionary, ExcelReportParameter, FilterModelType, ReportSource } from "../../api/biApi.types";
import { BackButtonDefault } from "../components/common/BackButton";
import SearchField from "../components/common/SearchField";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import HorizontalFill from "../components/common/HorizontalFill";
import { getParameterText, getParameterValue } from "./helpers/parametersHelper";
import ScrollableFlexContainer from "../components/common/ScrollableFlexContainer";
import { getBooleanEquityType, getEquityType, getHeaderValues } from "./utils/parametersHelper";

interface Props {
  info: ExcelReportParameter;
  values: BcParameterDictionary[] | undefined;
  originalSelectedValues: string[];
  isLoading: boolean;
  onBack: () => void;
  onApply: (values: string[], equalityType: FilterModelType) => void;
  equalityType: FilterModelType;
}
export default function SelectParameterValue({
  values,
  info,
  originalSelectedValues,
  onBack,
  onApply,
  isLoading,
  equalityType,
}: Props) {
  const [search, setSearch] = React.useState("");
  const [selectedValues, setSelectedValues] = React.useState<string[]>(
    cloneDeep(originalSelectedValues.map((v) => v ?? ""))
  );
  const [equality, setEquality] = React.useState(equalityType);

  const mappedValues = React.useMemo(() => {
    return values?.map((v) => ({ value: getParameterValue(v), text: getParameterText(v) }));
  }, [values]);

  const columns = React.useMemo(() => {
    const { valueHeaderName, textHeaderName } = getHeaderValues(values);
    return [
      { field: "value", headerName: valueHeaderName, disableColumnMenu: true, flex: 1, disableReorder: true },
      { field: "text", headerName: textHeaderName, disableColumnMenu: true, flex: 1, disableReorder: true },
    ];
  }, [values]);

  const filteredValues = React.useMemo(() => {
    if (!search) return mappedValues;
    return mappedValues?.filter(
      (v) =>
        v.value?.toLowerCase().includes(search.toLowerCase()) || v.text?.toLowerCase().includes(search.toLowerCase())
    );
  }, [mappedValues, search]);

  const handleValueSelect = React.useCallback(
    (newSelectedValues: string[]) => {
      setSelectedValues((prev) => [
        ...newSelectedValues,
        ...prev.filter((sv) => !filteredValues?.some((v) => v.value === sv)),
      ]);
    },
    [setSelectedValues, filteredValues]
  );

  return (
    <Grid container sx={{ flex: 1, flexDirection: "column", gap: 1 }}>
      <Grid container sx={{ px: 2, py: 0.5, gap: 1, flexDirection: "column" }}>
        <Grid>
          <BackButtonDefault onClick={onBack} />
        </Grid>
        <Typography variant="subtitle2">{info.description}</Typography>
        <SearchField placeholder="Search..." onSearch={setSearch} />
        {info.reportSource === ReportSource.BI && (
          <Grid container sx={{ pl: 0.5 }}>
            <Typography variant="body2">Exclude values</Typography>
            <HorizontalFill />
            <Switch
              size="small"
              checked={getBooleanEquityType(equality)}
              onChange={(_, value) => {
                setEquality(getEquityType(value));
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container flex={1} px={2}>
        <ScrollableFlexContainer>
          <DataGridPremium
            slots={{
              loadingOverlay: InlineLoader,
            }}
            loading={isLoading}
            columns={columns}
            rows={filteredValues || []}
            sx={(theme) => ({
              minHeight: "255px",
              border: "1px solid " + theme.palette.divider,
              cursor: "pointer",
              ".MuiDataGrid-columnHeaders": {
                borderBottom: "1px solid " + theme.palette.divider,
              },
              ".MuiDataGrid-cell:focus-within": {
                outline: "unset",
              },
              ".MuiDataGrid-columnHeader:focus-within": {
                outline: "unset",
              },
            })}
            rowHeight={24}
            checkboxSelection
            rowSelectionModel={selectedValues}
            onRowSelectionModelChange={(model) => handleValueSelect(model as string[])}
            hideFooter
            columnHeaderHeight={30}
            getRowId={(row) => getParameterValue(row)}
          ></DataGridPremium>
        </ScrollableFlexContainer>
      </Grid>
      <Divider />
      <Grid container sx={{ gap: 1, justifyContent: "end", px: 2, pb: 1 }}>
        <Button variant="text" color="secondary" size="small" onClick={onBack}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onApply(selectedValues, equality)}
          disabled={isLoading}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  );
}
