import { Button, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectReports, selectReportsLoading } from "../../../store/reportsSlice";
import { useReportServiceContext } from "../../../contexts/ReportServiceContext";
import RefreshAllReportsIcon from "../../../icons/RefreshAllReportsIcon";
import { PageNames } from "../../../types";
import { selectUserPermissions } from "../../../store/userSlice";
import { hasAccessToReport, hasPermissionsForAnyCompany } from "../../helpers/accessUtils";
import { XEntriliaReportDescriptor } from "../../../store/store.types";

interface Props {
  activeReport: XEntriliaReportDescriptor | undefined;
}

export default function RefreshReportsButton({ activeReport }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const permissions = useSelector(selectUserPermissions);
  const isReadonlyMode = !hasPermissionsForAnyCompany(permissions);

  return (
    <>
      <Button
        variant="text"
        size="small"
        color="secondary"
        startIcon={<CachedRoundedIcon />}
        endIcon={<ArrowDropDownRoundedIcon />}
        disabled={isReadonlyMode}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Refresh
      </Button>
      {anchorEl && <RefreshMenu anchorEl={anchorEl} activeReport={activeReport} onClose={() => setAnchorEl(null)} />}
    </>
  );
}

interface MenuProps {
  anchorEl: HTMLElement | null;
  activeReport: XEntriliaReportDescriptor | undefined;
  onClose: () => void;
}

function RefreshMenu({ anchorEl, activeReport, onClose }: MenuProps) {
  const navigate = useNavigate();
  const reports = useSelector(selectReports);
  const permissions = useSelector(selectUserPermissions);
  const loadingReports = useSelector(selectReportsLoading);
  const { refreshReport } = useReportServiceContext();

  const canRefreshCurrentReport = React.useMemo(() => {
    const reportLoading = loadingReports.find((lr) => lr.id === activeReport?.id);
    return reportLoading === undefined || !reportLoading.loading;
  }, [activeReport, loadingReports]);

  const accessibleReports = useMemo(
    () => reports.filter((r) => hasAccessToReport(r, permissions)),
    [reports, permissions]
  );

  const handleRefreshCurrentReport = React.useCallback(() => {
    if (activeReport !== undefined) refreshReport(activeReport.id);
    onClose();
  }, [activeReport, onClose, refreshReport]);

  const handleRefreshAllReports = React.useCallback(() => {
    const notLoadingReports = accessibleReports.filter(
      (r) => !loadingReports.some((lr) => lr.id === r.id && lr.loading)
    );
    notLoadingReports.forEach((r) => refreshReport(r.id));
    onClose();
  }, [loadingReports, onClose, refreshReport, accessibleReports]);

  const navigateToEditReport = React.useCallback(
    () => navigate(`${PageNames.EditReport}/${activeReport?.id}`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeReport?.id]
  );

  const hasAccessToCurrentReport = activeReport && hasAccessToReport(activeReport, permissions);
  const hasAccessToAnyReport = accessibleReports.length > 0;

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem disabled={!canRefreshCurrentReport || !hasAccessToCurrentReport} onClick={handleRefreshCurrentReport}>
        <ListItemIcon>
          <CachedRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Quick Refresh (Current Tab)
        </Typography>
      </MenuItem>
      <MenuItem disabled={!canRefreshCurrentReport || !hasAccessToCurrentReport} onClick={navigateToEditReport}>
        <ListItemIcon>
          <TuneRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Refresh with new Filters (Current Tab)
        </Typography>
      </MenuItem>
      <MenuItem disabled={!canRefreshCurrentReport || !hasAccessToAnyReport} onClick={handleRefreshAllReports}>
        <ListItemIcon>
          <RefreshAllReportsIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Refresh All
        </Typography>
      </MenuItem>
    </Menu>
  );
}
