import { useCallback, useEffect, useRef } from "react";

interface Cancellation {
  id: string | undefined;
  abortController?: AbortController;
  requestIsCancelled: boolean;
  isCancelled: () => boolean;
}

const useCancellation = () => {
  const cancellationsRef = useRef<Cancellation[]>([]);

  const removeCancellation = useCallback((reportCode: string | undefined, workSheetId: string) => {
    cancellationsRef.current = cancellationsRef.current.filter(
      (c) => c.id !== getCancellationIdentifier(reportCode, workSheetId)
    );
  }, []);

  const cancelRequest = useCallback((reportCode: string | undefined, workSheetId: string) => {
    const ct = cancellationsRef.current.find((c) => c.id === getCancellationIdentifier(reportCode, workSheetId));
    if (ct !== undefined) {
      ct.requestIsCancelled = true;
      ct.abortController?.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCancellation = useCallback(
    (reportCode: string | undefined, workSheetId: string) => {
      removeCancellation(reportCode, workSheetId);

      cancellationsRef.current.push({
        id: getCancellationIdentifier(reportCode, workSheetId),
        abortController: new AbortController(),
        requestIsCancelled: false,
        isCancelled: () => {
          const ct = cancellationsRef.current.find((c) => c.id === getCancellationIdentifier(reportCode, workSheetId));
          return ct?.requestIsCancelled || ct?.abortController?.signal.aborted || false;
        },
      });

      return cancellationsRef.current[cancellationsRef.current.length - 1];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => () => {
      cancellationsRef.current.forEach((c) => c.abortController?.abort());
    },
    []
  );

  return { setCancellation, cancelRequest, removeCancellation };
};

export default useCancellation;

const getCancellationIdentifier = (reportCode: string | undefined, workSheetId: string) =>
  `${reportCode}-${workSheetId}`;
