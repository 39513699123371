import { Box, SxProps, Theme, Typography } from "@mui/material";
import InlineLoaderSvg from "./InlineLoaderSvg";

interface Props {
  sx?: SxProps<Theme>;
  text?: string;
}

export default function InlineLoader({ sx, text }: Props) {
  return (
    <Box
      position={"absolute"}
      width={"100%"}
      height={"100%"}
      display="flex"
      flexGrow={1}
      flexDirection={"column"}
      alignItems="center"
      justifyContent="center"
      gap={1}
      sx={{
        pointerEvents: "none",
        ...sx,
      }}
    >
      <Box sx={{ width: "4rem" }}>
        <InlineLoaderSvg />
      </Box>
      {text && <Typography variant="subtitle1">{text}</Typography>}
    </Box>
  );
}
