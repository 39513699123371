import { Typography } from "@mui/material";
import { ExcelReportParameter } from "../../../api/biApi.types";

interface Props {
  item: ExcelReportParameter;
  required: boolean;
}
export default function FieldLabel({ item, required }: Props) {
  return (
    <Typography variant="body2" color={"secondary"}>
      {item.description || item.name}
      {required && (
        <Typography
          component="span"
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
        >
          *
        </Typography>
      )}
    </Typography>
  );
}
