import { XColumn } from "../hooks/useExcelDataApi.types";

export async function loadColumnsWidth(context: Excel.RequestContext, sheet: Excel.Worksheet, columns: XColumn[]) {
  context.application.suspendApiCalculationUntilNextSync();
  context.application.suspendScreenUpdatingUntilNextSync();

  let hasSynced = true;

  for (let index = 0; index < columns.length; index++) {
    const column = columns[index];
    if (column === undefined) continue;
    hasSynced = false;

    const range = sheet.getRangeByIndexes(0, index, 1, 1).getEntireColumn();
    if (column.width !== undefined) {
      range.format.columnWidth = column.width;
    }

    if (index > 0 && index % 3000 === 0) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      hasSynced = true;
      context.application.suspendApiCalculationUntilNextSync();
      context.application.suspendScreenUpdatingUntilNextSync();
    }
  }

  if (!hasSynced) {
    await context.sync();
  }
}

export async function columnsAutofit(context: Excel.RequestContext, sheet: Excel.Worksheet, columns: XColumn[]) {
  context.application.suspendApiCalculationUntilNextSync();
  context.application.suspendScreenUpdatingUntilNextSync();

  let hasSynced = true;

  for (let index = 0; index < columns.length; index++) {
    const column = columns[index];
    if (column === undefined) continue;
    hasSynced = false;

    const range = sheet.getRangeByIndexes(0, index, 1, 1).getEntireColumn();
    if (column.bestFit) {
      range.format.autofitColumns();
    }

    if (index > 0 && index % 3300 === 0) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      hasSynced = true;
      context.application.suspendApiCalculationUntilNextSync();
      context.application.suspendScreenUpdatingUntilNextSync();
    }
  }
  if (!hasSynced) {
    await context.sync();
  }
}
