import { Grid, Typography, LinearProgress } from "@mui/material";
import { XEntriliaReportDescriptor } from "../../store/store.types";

interface Props {
  report: XEntriliaReportDescriptor;
}
export default function WorkbookReportItemDeletingContent({ report }: Props) {
  return (
    <Grid container sx={{ flexDirection: "row", flex: 1 }}>
      <Grid item xs={11}>
        <Grid container gap={0.5} direction="column">
          <Typography
            sx={(theme) => ({
              flex: 1,
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "14.4px",
              color: theme.palette.text.disabled,
            })}
          >
            {report.caption}
          </Typography>
          <Grid sx={{ flex: 1 }}>
            <LinearProgress color="error" sx={{ flex: 1, borderRadius: "3px" }} />
          </Grid>
          <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.error.main })}>
            Deleting the report...
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
