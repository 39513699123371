import { Button } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";

interface Props {
  pageNavigateTo?: string;
}

export default function BackButton({ pageNavigateTo }: Props) {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (pageNavigateTo) {
      navigate(pageNavigateTo);
      return;
    }
    navigate("/");
  };
  return <BackButtonDefault onClick={handleNavigate} />;
}

export function BackButtonDefault({ onClick }: { onClick: () => void }) {
  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<ArrowBackRoundedIcon />}
      onClick={onClick}
      sx={{ px: 0.6, py: 0.5 }}
    >
      Back
    </Button>
  );
}
