import { Grid, IconButton, ListItem, Typography } from "@mui/material";
import HorizontalFill from "../components/common/HorizontalFill";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import { ExcelReportColumnWrapper } from "./SelectedColumnItem.types";
import { useCallback, useRef } from "react";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd";

export const DEFAULT_AREA_FIELD_ITEM_HEIGHT = 40;

interface Props {
  item: ExcelReportColumnWrapper;
  canDrop: boolean;
  addShifting: boolean;
  hideDraggingItem: boolean;
  draggingItemMovedFromInitialPosition: boolean;
  onRemoveColumn: (id: number) => void;
  onItemHovered: (field: ExcelReportColumnWrapper) => void;
  onEndReordering: (field: ExcelReportColumnWrapper) => void;
}

export default function SelectedColumnItem({
  item,
  canDrop,
  addShifting,
  hideDraggingItem,
  draggingItemMovedFromInitialPosition,
  onRemoveColumn,
  onItemHovered,
  onEndReordering,
}: Props) {
  const listItemRef = useRef<HTMLLIElement>(null);

  const collect = useCallback((monitor: DragSourceMonitor<ExcelReportColumnWrapper, unknown>) => {
    return {
      isDragging: monitor.isDragging(),
    };
  }, []);

  const end = useCallback(
    (item: ExcelReportColumnWrapper, monitor: DragSourceMonitor<ExcelReportColumnWrapper, unknown>) => {
      if (monitor.didDrop()) {
        onEndReordering(item);
      }
    },
    [onEndReordering]
  );

  const hover = useCallback(
    (_: ExcelReportColumnWrapper, monitor: DropTargetMonitor<ExcelReportColumnWrapper>) => {
      if (monitor.isOver()) {
        onItemHovered(item);
      }
    },
    [item, onItemHovered]
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Item",
      item,
      collect,
      end,
    }),
    [end, item]
  );

  const [, drop] = useDrop(() => {
    return {
      accept: ["Item"],
      hover,
      canDrop: () => canDrop,
    };
  }, [hover, canDrop]);

  drag(drop(listItemRef));

  return (
    <ListItem
      role="Handle"
      ref={listItemRef}
      data-index={item.index}
      data-is-dragging={isDragging}
      data-is-shifted={addShifting}
      sx={{
        display: hideDraggingItem ? "none" : "flex",
        flexDirection: "column",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "divider",
        borderRadius: "2px",
        p: 0,
        bgcolor: "#fff",
        overflow: "hidden",
        height: !isDragging ? "auto" : `${DEFAULT_AREA_FIELD_ITEM_HEIGHT}px`,
        minHeight: `${DEFAULT_AREA_FIELD_ITEM_HEIGHT}px`,
      }}
      style={{
        ...(addShifting && {
          transform: "translate(0px, 40px)",
        }),
        ...(draggingItemMovedFromInitialPosition
          ? { transition: "transform 0.2s cubic-bezier(0.2, 0, 0, 1)" }
          : { transition: "none 0s ease 0s" }),
      }}
    >
      <Grid
        key={item.column.id}
        container
        alignItems="center"
        sx={{
          gap: 1,
          px: 1,
          py: 0.5,
        }}
      >
        <DragIndicatorRoundedIcon fontSize="small" sx={{ color: "action.active", cursor: "grab" }} />
        <Typography variant="body1">{item.column.name}</Typography>
        <HorizontalFill />
        <IconButton size="small" sx={{ color: "action.active" }} onClick={() => onRemoveColumn(item.column.id)}>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Grid>
    </ListItem>
  );
}
