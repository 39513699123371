export default class XRange {
  public startCol: number;
  public startRow: number;
  public endCol: number;
  public endRow: number;

  constructor(public address: string) {
    const { startRow, startCol, endRow, endCol } = parseRangeAddress(address);
    this.startCol = startCol;
    this.startRow = startRow;
    this.endCol = endCol;
    this.endRow = endRow;
  }

  public nonIntersectingColumns(range: XRange) {
    // Calculate non-intersecting column ranges
    const nonIntersectingColumnsStartRow = Math.min(this.startRow, range.startRow);
    const nonIntersectingColumnsEndRow = Math.min(this.endRow, range.endRow);
    const nonIntersectingColumns = {
      startRow: nonIntersectingColumnsStartRow,
      startCol: this.endCol + 1,
      endRow: nonIntersectingColumnsEndRow,
      endCol: range.endCol,
    };

    // Validate if there is a non-intersecting range for columns
    const nonIntersectingColumnsRange =
      nonIntersectingColumns.startCol <= nonIntersectingColumns.endCol
        ? constructRangeAddress(nonIntersectingColumns)
        : undefined;
    return nonIntersectingColumnsRange;
  }

  public nonIntersectingRows(range: XRange) {
    // Calculate non-intersecting row ranges
    const nonIntersectingRowsStartCol = Math.min(this.startCol, range.startCol);
    const nonIntersectingRowsEndCol = Math.min(this.endCol, range.endCol);
    const nonIntersectingRows = {
      startRow: this.endRow + 1,
      startCol: nonIntersectingRowsStartCol,
      endRow: range.endRow,
      endCol: nonIntersectingRowsEndCol,
    };
    const nonIntersectingRowsRange =
      nonIntersectingRows.startRow <= nonIntersectingRows.endRow
        ? constructRangeAddress(nonIntersectingRows)
        : undefined;

    return nonIntersectingRowsRange;
  }

  public moveToRight(): string {
    //move to the right whole range by size of columns range
    const newRange = {
      startRow: this.startRow,
      startCol: this.endCol + 1,
      endRow: this.endRow,
      endCol: this.endCol + (this.endCol - this.startCol) + 1,
    };
    return constructRangeAddress(newRange);
  }

  public moveToBottom(): string {
    //move to the bottom whole range by size of rows range
    const newRange = {
      startRow: this.endRow + 1,
      startCol: this.startCol,
      endRow: this.endRow + (this.endRow - this.startRow) + 1,
      endCol: this.endCol,
    };
    return constructRangeAddress(newRange);
  }
}

function parseRangeAddress(address: string) {
  const match = address.match(/^([A-Z]+)(\d+):([A-Z]+)(\d+)$/);

  if (!match) {
    throw new Error(`Invalid range address: ${address}`);
  }

  const [, startColStr, startRowStr, endColStr, endRowStr] = match;
  if (!startColStr || !startRowStr || !endColStr || !endRowStr) throw new Error(`Invalid range address: ${address}`);

  const startCol = columnNameToIndex(startColStr);
  const startRow = parseInt(startRowStr, 10) - 1;
  const endCol = columnNameToIndex(endColStr);
  const endRow = parseInt(endRowStr, 10) - 1;

  return { startRow, startCol, endRow, endCol };
}

function columnNameToIndex(columnName: string) {
  let index = 0;
  for (let i = 0; i < columnName.length; i++) {
    index = index * 26 + (columnName.charCodeAt(i) - 64);
  }
  return index - 1;
}

function constructRangeAddress(range: { startRow: number; startCol: number; endRow: number; endCol: number }) {
  const startColStr = indexToColumnName(range.startCol);
  const endColStr = indexToColumnName(range.endCol);
  const startRowStr = (range.startRow + 1).toString();
  const endRowStr = (range.endRow + 1).toString();

  return `${startColStr}${startRowStr}:${endColStr}${endRowStr}`;
}

function indexToColumnName(index: number) {
  let columnName = "";
  while (index >= 0) {
    columnName = String.fromCharCode((index % 26) + 65) + columnName;
    index = Math.floor(index / 26) - 1;
  }
  return columnName;
}
