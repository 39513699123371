import { useDispatch, useSelector } from "react-redux";
import {
  addAvailableCompanies,
  selectAllAvailableCompanies,
  selectAvailableCompanyReports,
  selectAvailableCompaniesLoaded,
} from "../../../store/reportsSlice";
import React, { useEffect } from "react";
import useRequest from "../../../hooks/useRequest";
import { BiApi } from "../../../api/biApi";

interface Props {
  clientCode: string | undefined;
}

export default function useAvailableReports({ clientCode }: Props) {
  const availableCompanies = useSelector(selectAllAvailableCompanies);
  const availableCompanyReports = useSelector((state) => selectAvailableCompanyReports(state, clientCode));
  const availableReportsLoaded = useSelector((state) => selectAvailableCompaniesLoaded(state, clientCode));

  const dispatch = useDispatch();
  const clientCodeRef = React.useRef<string>("");

  const getReports = React.useCallback(async () => BiApi.getAvailableReports(clientCodeRef.current), []);

  const { request, isLoading } = useRequest(getReports);
  const [loadingReportsError, setLoadingReportsError] = React.useState<string | undefined>();

  const reloadAvailableReports = React.useCallback(
    async (code: string) => {
      clientCodeRef.current = code;
      const response = await request();
      if (response.error === undefined) {
        setLoadingReportsError(undefined);
        dispatch(
          addAvailableCompanies({
            companyCode: code,
            reports: response.data || [],
          })
        );

        return;
      }
      setLoadingReportsError(response.error.message);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request]
  );

  const retryToLoadReports = React.useCallback(
    (code: string) => {
      setLoadingReportsError(undefined);
      reloadAvailableReports(code);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request]
  );

  useEffect(() => {
    if (clientCode && !availableCompanies.find((r) => r.companyCode === clientCode)) {
      reloadAvailableReports(clientCode);
    }
  }, [clientCode, reloadAvailableReports, availableCompanies]);

  return {
    isLoading,
    loadingReportsError,
    retryToLoadReports,
    reloadAvailableReports,
    availableCompanyReports,
    availableReportsLoaded,
  };
}
