import { SvgIcon, SvgIconProps } from "@mui/material";

export default function DocumentIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ width: 16, height: 16, fill: "none", stroke: "#8E9BA9", ...props.sx }}
      viewBox="0 0 16 16"
    >
      <path
        d="M6.00004 8H10M6.00004 10.6667H10M11.3334 14H4.66671C4.31309 14 3.97395 13.8595 3.7239 13.6095C3.47385 13.3594 3.33337 13.0203 3.33337 12.6667V3.33333C3.33337 2.97971 3.47385 2.64057 3.7239 2.39052C3.97395 2.14048 4.31309 2 4.66671 2H8.39071C8.56751 2.00004 8.73705 2.0703 8.86204 2.19533L12.4714 5.80467C12.5964 5.92966 12.6667 6.0992 12.6667 6.276V12.6667C12.6667 13.0203 12.5262 13.3594 12.2762 13.6095C12.0261 13.8595 11.687 14 11.3334 14Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
