import React, { useEffect } from "react";
import useAvailableReports from "../components/hooks/useAvailableReports";
import AvailableReportsList from "./AvailableReportsList";
import { ExcelReport } from "../../api/biApi.types";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveWorksheetId } from "../../store/workbookSlice";
import SetParametersStep from "../parameters/SetParametersStep";
import { deleteReportById, selectReportsLoading, updateTrigger } from "../../store/reportsSlice";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import ReportGeneration from "../components/ReportGeneration";
import {
  UserInfoActions,
  selectClientsWithAccess,
  selectSelectedCompany,
  selectUserPermissions,
} from "../../store/userSlice";
import LoadingReportsError from "../errors/LoadingReportsError";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import BackButton from "../components/common/BackButton";
import CompanySelection from "./CompanySelection";
import { setStoredSelectedCompanyCode } from "../components/utils/storedClient";
import NoAvailableCompanies from "./NoAvailableCompanies";
import { XEntriliaReportParameter } from "../../store/store.types";

export default function AddReportPage() {
  const { addReport, refreshReport, cancelRequest } = useReportServiceContext();
  const navigate = useNavigate();
  const permissions = useSelector(selectUserPermissions);
  const clientsWithAccess = useSelector(selectClientsWithAccess);
  const activeWorksheetId = useSelector(selectActiveWorksheetId);
  const lastSelectedCompany = useSelector(selectSelectedCompany);
  const reportsLoading = useSelector(selectReportsLoading);

  const { isLoading, loadingReportsError, retryToLoadReports, availableCompanyReports, availableReportsLoaded } =
    useAvailableReports({
      clientCode: lastSelectedCompany,
    });

  const dispatch = useDispatch();

  const [selectedReport, setSelectedReport] = React.useState<ExcelReport | undefined>();
  const [filters, setFilters] = React.useState<XEntriliaReportParameter[]>([]);
  const [withParameters, setWithParameters] = React.useState(false);
  const [selectedColumns, setSelectedColumns] = React.useState<number[]>([]);
  const [generateReport, setGenerateReport] = React.useState(false);
  const [reportId, setReportId] = React.useState<string>();

  const loadingReport = React.useMemo(() => {
    return reportsLoading?.find((lr) => lr.id === reportId);
  }, [reportId, reportsLoading]);

  const requestReportExport = React.useCallback(async () => {
    let newReportId;
    if (activeWorksheetId && selectedReport && lastSelectedCompany !== undefined) {
      newReportId = await addReport(
        activeWorksheetId,
        selectedReport,
        filters,
        lastSelectedCompany,
        withParameters,
        selectedColumns
      );
    }
    setReportId(newReportId);
  }, [activeWorksheetId, selectedReport, lastSelectedCompany, addReport, filters, withParameters, selectedColumns]);

  const goHome = React.useCallback(() => {
    if (reportId !== undefined) {
      dispatch(deleteReportById(reportId));
    }
    dispatch(updateTrigger());
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  const handleSelectCompany = (company: string) => {
    setStoredSelectedCompanyCode(company);
    dispatch(UserInfoActions.setSelectedCompany(company));
  };

  useEffect(() => {
    setSelectedColumns(selectedReport?.selectedColumns ?? []);
  }, [selectedReport]);

  return (
    <>
      {selectedReport === undefined && (
        <Stack flex={1} spacing={1.5} py={2} px={2.5}>
          <Box>
            <BackButton pageNavigateTo={"/"} />
          </Box>
          <Stack spacing={2.5} height={"100%"}>
            {clientsWithAccess.length === 0 && <NoAvailableCompanies />}
            {clientsWithAccess.length > 0 && (
              <>
                <CompanySelection
                  onCompanyChange={handleSelectCompany}
                  clients={clientsWithAccess}
                  permissions={permissions}
                  defaultClientCode={lastSelectedCompany}
                  isLoading={isLoading}
                />
                {loadingReportsError === undefined && (
                  <Stack spacing={1} height={"100%"}>
                    <Typography variant="subtitle1">Reports</Typography>
                    {!lastSelectedCompany && (
                      <Typography variant="body1" color="text.secondary" fontSize={13}>
                        Please select a company
                      </Typography>
                    )}
                    {lastSelectedCompany && (
                      <AvailableReportsList
                        loading={isLoading}
                        availableReports={availableCompanyReports}
                        onReportSelected={(r) => {
                          setSelectedReport(r);
                          setFilters([]);
                        }}
                        reportsLoaded={availableReportsLoaded}
                      />
                    )}
                  </Stack>
                )}
                {loadingReportsError !== undefined && (
                  <LoadingReportsError
                    goHome={goHome}
                    retryToLoadReports={() => {
                      if (lastSelectedCompany) {
                        retryToLoadReports(lastSelectedCompany);
                      }
                    }}
                    errorMessage={
                      "An error has occurred while loading the report. Please try again, or contact your system administrator if the error persists."
                    }
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      )}
      {selectedReport && !generateReport && activeWorksheetId && lastSelectedCompany && (
        <SetParametersStep
          report={selectedReport}
          originalFilters={filters}
          submitButtonText="Add report"
          exportWithParameters={withParameters}
          clientCode={lastSelectedCompany}
          submitButtonDisabled={loadingReport !== undefined}
          selectedColumns={selectedColumns}
          onBack={() => setSelectedReport(undefined)}
          onContinue={(newFilters) => {
            setFilters(newFilters);
            setGenerateReport(true);
          }}
          onChangeExportWithParameters={setWithParameters}
          onColumnsSelected={setSelectedColumns}
        />
      )}
      {selectedReport && generateReport && activeWorksheetId && (
        <ReportGeneration
          handleReport={requestReportExport}
          refreshReport={refreshReport}
          goHome={goHome}
          cancelRequest={() => {
            setGenerateReport(false);
            cancelRequest(selectedReport.code, activeWorksheetId);
          }}
          reportId={reportId}
          loadingReport={loadingReport}
        />
      )}
    </>
  );
}
