export async function excelLoad<K extends keyof T, T extends { load: (propertyNames: K) => unknown }>(
  context: Excel.RequestContext,
  obj: T,
  properties: K[]
): Promise<T[K][]> {
  obj.load(properties.join(",") as K);
  await context.sync();
  return properties.map((p) => obj[p]);
}

export async function excelLoadSingle<K extends keyof T, T extends { load: (propertyNames: K) => unknown }>(
  context: Excel.RequestContext,
  obj: T,
  property: K
): Promise<T[K]> {
  obj.load(property);
  await context.sync();
  return obj[property];
}
