import { SvgIcon, SvgIconProps } from "@mui/material";

export default function RefreshAllReportsIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 20, height: 20, fill: "none" }} viewBox="0 0 20 20" {...props}>
      <path
        d="M4.99999 1.66663C4.55833 1.66663 4.13333 1.84163 3.825 2.15829C3.50833 2.46663 3.33333 2.89163 3.33333 3.33329V16.6666C3.33333 17.1083 3.50833 17.5333 3.825 17.8416C4.13333 18.1583 4.55833 18.3333 4.99999 18.3333H10.8333C10.525 17.8333 10.2833 17.2666 10.1417 16.6666H4.99999V3.33329H10.8333V7.49996H15V9.99996H15.4167C15.8333 9.99996 16.25 10.05 16.6667 10.1416V6.66663L11.6667 1.66663H4.99999ZM10 15C10.0583 14.4166 10.2 13.85 10.4167 13.3333H6.66666V15H10ZM11.5083 11.6666C12.025 11.1333 12.6417 10.7083 13.3333 10.4166V9.99996H6.66666V11.6666H11.5083ZM15 12.0833C15.925 12.0833 16.7583 12.4583 17.3583 13.0583L18.3333 12.0833V15.4166H15L16.475 13.9416C16.1 13.5666 15.575 13.3333 15 13.3333C13.85 13.3333 12.9167 14.2666 12.9167 15.4166C12.9167 16.5666 13.85 17.5 15 17.5C15.6833 17.5 16.2833 17.175 16.6667 16.6666H18.0917C17.6 17.8916 16.4 18.75 15 18.75C13.1583 18.75 11.6667 17.2583 11.6667 15.4166C11.6667 13.575 13.1583 12.0833 15 12.0833Z"
        fill="#8E9BA9"
      />
    </SvgIcon>
  );
}
