import { Collapse, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AvailableReportItem from "./AvailableReportItem";
import { ExcelReport } from "../../api/biApi.types";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

export interface ReportsGroup {
  name: string;
  reports: ExcelReport[];
}

export interface Group {
  type: string;
  reportGroups: ReportsGroup[];
}

interface Props {
  group: ReportsGroup;
  expanded: boolean;
  onExpandChanged?: (expanded: boolean) => void;
  onReportSelected: (report: ExcelReport) => void;
}

const AvailableReportGroup = ({ group, expanded, onExpandChanged, onReportSelected }: Props) => {
  const [localExpanded, setLocalExpanded] = useState<boolean>(expanded);

  useEffect(() => {
    setLocalExpanded(expanded);
  }, [expanded]);

  return (
    <Grid container key={group.name} sx={{ flex: 1, flexDirection: "column" }}>
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <IconButton
          size="small"
          onClick={() => {
            setLocalExpanded(!localExpanded);
            onExpandChanged?.(!localExpanded);
          }}
        >
          {localExpanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
        </IconButton>
        <Typography variant="subtitle2">{group.name}</Typography>
      </Stack>
      <Collapse in={localExpanded} timeout="auto" sx={{ maxWidth: "100%" }}>
        <Stack sx={{ gap: 0.8 }}>
          {group.reports.map((ar) => (
            <AvailableReportItem key={ar.code} report={ar} onReportSelected={onReportSelected} />
          ))}
        </Stack>
      </Collapse>
    </Grid>
  );
};

export default AvailableReportGroup;
