import { createSelector, createSlice } from "@reduxjs/toolkit";
import { generateGuid } from "../utils/Utilities";
import { ExcelReport } from "../api/biApi.types";
import {
  ReportsConfiguration,
  RootState,
  StepEnum,
  XEntriliaReportDescriptor,
  XEntriliaReportLoading,
} from "./store.types";

const initialState: ReportsConfiguration = {
  reports: [],
  availableCompanies: [],
  reportsLoading: [],
  reportsDeleting: [],
};

const reportsSlice = createSlice({
  name: "reportsConfiguration",
  initialState,
  reducers: {
    updateReports(state, action: { payload: XEntriliaReportDescriptor[] | undefined }) {
      const ids = action.payload?.map((r) => r.id);
      state.reports = action.payload || [];
      state.reportsDeleting = state.reportsDeleting.filter((r) => ids?.includes(r.id));
    },
    updateReport(state, action: { payload: XEntriliaReportDescriptor }) {
      const reportIndex = state.reports.findIndex((r) => r.id === action.payload.id);
      if (reportIndex !== -1) {
        state.reports[reportIndex] = action.payload;
      }
    },
    addReport(state, action: { payload: XEntriliaReportDescriptor }) {
      state.reports.push(action.payload);
    },
    deleteReportById(state, action: { payload: string }) {
      state.reports = state.reports.filter((r) => r.id !== action.payload);
    },
    setActiveReport(state, action: { payload: XEntriliaReportDescriptor | undefined }) {
      state.activeReport = action.payload;
    },
    updateTrigger(state) {
      state.trigger = generateGuid();
    },
    addReportToLoading(state, action: { payload: XEntriliaReportDescriptor }) {
      const { payload } = action;
      const report = state.reportsLoading?.find((r) => r.id === payload.id);
      if (report === undefined) {
        state.reportsLoading.push({
          id: payload.id,
          loading: true,
          step: StepEnum.Pending,
          stepDescription: "Pending",
          name: payload.caption,
          cancellable: true,
        });
      } else {
        Object.assign(report, { loading: true, step: StepEnum.Pending, stepDescription: "Pending", error: undefined });
      }
    },
    updateReportLoading(state, action: { payload: Partial<XEntriliaReportLoading> }) {
      const { payload } = action;
      const { id } = payload;
      const report = state.reportsLoading?.find((r) => r.id === id);
      if (report) {
        Object.assign(report, payload);
      }
    },
    removeReportFromLoading(state, action: { payload: string }) {
      const { payload } = action;
      const reportIndex = state.reportsLoading?.findIndex((r) => r.id === payload);
      if (reportIndex !== undefined && reportIndex > -1) {
        state.reportsLoading?.splice(reportIndex, 1);
      }
    },
    addReportToDeleting(state, action: { payload: XEntriliaReportDescriptor }) {
      const { payload } = action;
      const report = state.reportsDeleting?.find((r) => r.id === payload.id);
      if (report === undefined) {
        state.reportsDeleting.push({ id: payload.id });
      }
    },
    addAvailableCompanies(state, action: { payload: { companyCode: string; reports: ExcelReport[] } }) {
      const { companyCode, reports } = action.payload;
      const companyIndex = state.availableCompanies.findIndex((r) => r.companyCode === companyCode);

      if (companyIndex === -1) {
        state.availableCompanies.push({ companyCode, reports, areReportsLoaded: true });
        return;
      }
      Object.assign(state.availableCompanies[companyIndex] || { companyCode, reports }, {
        reports,
      });
    },
  },
});

export const {
  updateReports,
  addReport,
  setActiveReport,
  updateTrigger,
  addReportToLoading,
  updateReportLoading,
  removeReportFromLoading,
  deleteReportById,
  addReportToDeleting,
  addAvailableCompanies,
  updateReport,
} = reportsSlice.actions;

export const selectReports = (state: RootState) => state.reports.reports;
export const selectActiveReport = (state: RootState) => state.reports.activeReport;
export const selectTrigger = (state: RootState) => state.reports.trigger;
export const selectReportsLoading = (state: RootState) => state.reports.reportsLoading;
export const selectReportsDeleting = (state: RootState) => state.reports.reportsDeleting;
export const selectAvailableCompanyReports = createSelector(
  [(state: RootState) => state.reports.availableCompanies, (_, companyCode: string | undefined) => companyCode],
  (availableCompanies, companyCode) => availableCompanies.find((r) => r.companyCode === companyCode)?.reports || []
);
export const selectAvailableCompaniesLoaded = createSelector(
  [(state: RootState) => state.reports.availableCompanies, (_, companyCode: string | undefined) => companyCode],
  (availableCompanies, companyCode) =>
    availableCompanies.find((r) => r.companyCode === companyCode)?.areReportsLoaded || false
);
export const selectAllAvailableCompanies = (state: RootState) => state.reports.availableCompanies;

export default reportsSlice.reducer;
