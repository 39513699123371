import { XMergeCell } from "../hooks/useExcelDataApi.types";

export default async function mergeCells(context: Excel.RequestContext, sheet: Excel.Worksheet, cells: XMergeCell[]) {
  context.application.suspendApiCalculationUntilNextSync();
  context.application.suspendScreenUpdatingUntilNextSync();

  let hasSynced = true;

  for (let index = 0; index < cells.length; index++) {
    const cell = cells[index];
    if (cell === undefined) continue;
    hasSynced = false;

    const range = sheet.getRange(cell.reference);
    range.merge(false);

    if (index > 0 && index % 6000 === 0) {
      // eslint-disable-next-line office-addins/no-context-sync-in-loop
      await context.sync();
      hasSynced = true;
    }
  }

  if (!hasSynced) {
    await context.sync();
  }
}
