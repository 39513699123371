import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState, useEffect, useCallback } from "react";

interface Props {
  id: string;
  sheets: string[];
  onChange: (name: string) => void;
  label: string;
}

const ExcelSheetsDropdown = ({ id, sheets, onChange, label }: Props) => {
  const [sheet, setSheet] = useState("");
  const handleChange = useCallback((name: string) => {
    setSheet(name);
    onChange(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const sheetName = sheets[0];
    if (sheetName) {
      handleChange(sheetName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          labelId={id}
          label={label}
          id={`${id}-select`}
          value={sheet}
          onChange={(e) => handleChange(e.target.value)}
        >
          {sheets.map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ExcelSheetsDropdown;
