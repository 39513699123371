import { createSlice } from "@reduxjs/toolkit";
import { updateReports } from "./reportsSlice";
import { RootState, WorkbookState } from "./store.types";

const initialState: WorkbookState = {
  initialLoadingCompleted: false,
};

const workbookStateSlice = createSlice({
  name: "workbookState",
  initialState,
  reducers: {
    update(state, action: { payload: Partial<WorkbookState> }) {
      Object.assign(state, action.payload);
    },
    setActiveWorksheet(state, action: { payload: string | undefined }) {
      state.activeWorksheetId = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateReports, (state) => {
      state.initialLoadingCompleted = true;
    });
  },
});

export const { update, setActiveWorksheet } = workbookStateSlice.actions;

export const selectInitialLoadingCompleted = (state: RootState) => state.workbook.initialLoadingCompleted;
export const selectActiveWorksheetId = (state: RootState) => state.workbook.activeWorksheetId;

export default workbookStateSlice.reducer;
