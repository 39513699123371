import React from "react";
import { ExcelReportParameter } from "../../api/biApi.types";
import { Autocomplete, Grid, TextField } from "@mui/material";
import FieldLabel from "../components/common/FieldLabel";

interface Props {
  info: ExcelReportParameter;
  filterValues: string[];
  onUpdateFilter: (param: ExcelReportParameter, values: string[]) => void;
}
export function FilterItemOption({ info, filterValues, onUpdateFilter }: Props) {
  const [value, setValue] = React.useState<string | null>(() => getOptionFromValues(filterValues, info.options));

  const selectedItem = React.useMemo(() => {
    return info.options?.find((option) => option?.value === value) || null;
  }, [info.options, value]);

  React.useEffect(() => {
    const newValues = value === null ? [] : [value];
    onUpdateFilter(info, newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, onUpdateFilter]);

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <Grid container sx={{ flex: 1 }}>
        <Autocomplete
          size="small"
          sx={{ flex: 1 }}
          value={selectedItem}
          options={info.options || []}
          onChange={(_, value) => setValue(value?.value || null)}
          renderInput={(params) => <TextField {...params} />}
          getOptionLabel={(option) => option?.text}
        ></Autocomplete>
      </Grid>
    </>
  );
}

function getOptionFromValues(filterValues: string[], options: ExcelReportParameter["options"]) {
  if (filterValues.length === 0) {
    return options?.at(0)?.value || null;
  }

  return filterValues[0] || null;
}
