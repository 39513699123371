import { defined } from "./utils/typeHelper";

const variables = {
  MICROSOFT_CLIENT_ID: defined(process.env["MICROSOFT_CLIENT_ID"]),
  GOOGLE_CLIENT_ID: defined(process.env["GOOGLE_CLIENT_ID"]),
  PORTAL_REDIRECT_URI: defined(process.env["PORTAL_REDIRECT_URI"]),
  PORTAL_ACCESS_SCOPE: process.env["PORTAL_ACCESS_SCOPE"],
  PORTAL_BACKEND_BASE_URI: defined(process.env["PORTAL_BACKEND_BASE_URI"]),
  APP_INSIGHTS_CONN_STRING: process.env["APP_INSIGHTS_CONN_STRING"],
  LOGIN_APP_URI: defined(process.env["LOGIN_APP_URI"]),
};

const getSettings = () => variables;

const getBackendBaseUrl = (segment: string) => {
  const localServerUrl = process.env["LOCAL_SERVER"];
  if (localServerUrl) {
    return localServerUrl;
  }
  return `${variables.PORTAL_BACKEND_BASE_URI}/${segment}`;
};

export { getSettings, getBackendBaseUrl };
