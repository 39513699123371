import { ExcelReportColumn } from "../../api/biApi.types";
import { Grid, List } from "@mui/material";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getInsertToIndex, HoveredFieldItemIndex, hoveredIndexIsDefined } from "./utils/dragDropHelper";
import SelectedColumnItem, { DEFAULT_AREA_FIELD_ITEM_HEIGHT } from "./SelectedColumnItem";
import { ExcelReportColumnWrapper } from "./SelectedColumnItem.types";
import useDragLeave from "./hooks/useDragLeave";

interface Props {
  columns: ExcelReportColumn[];
  onOrderChanged: (columns: ExcelReportColumn[]) => void;
  onRemoveColumn: (id: number) => void;
}

export default function SelectedColumnsDropContainer({ columns, onRemoveColumn, onOrderChanged }: Props) {
  const fields = useMemo(
    () => columns.map((column, index): ExcelReportColumnWrapper => ({ column, index })),
    [columns]
  );
  const [hoveredIndex, setHoveredIndex] = useState<HoveredFieldItemIndex>("draggingIsOff");

  const [fieldItemIndexesForShifting, setFieldItemIndexesForShifting] = useState<number[]>();
  const [draggingItemMovedFromInitialPosition, setDraggingItemMovedFromInitialPosition] = useState(false);

  const listRef = useRef<HTMLUListElement | null>(null);
  const fieldsRef = useRef(fields);
  const hoveredIndexRef = useRef<HoveredFieldItemIndex>(hoveredIndex);
  hoveredIndexRef.current = hoveredIndex;
  fieldsRef.current = fields;

  const collect = useCallback((monitor: DropTargetMonitor) => {
    return {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
      draggingItem: monitor.getItem(),
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [{ canDrop, isOver, didDrop, draggingItem }, drop] = useDrop<any, any, any>(() => {
    return {
      accept: ["Item"],
      collect,
      drop: (item: ExcelReportColumnWrapper) => {
        return item;
      },
      canDrop: (_: ExcelReportColumnWrapper, monitor: DropTargetMonitor) => {
        if (!monitor.isOver()) {
          return false;
        }

        return true;
      },
    };
  }, []);

  const canDropRef = useRef(!!canDrop);
  canDropRef.current = !!canDrop;

  useDragLeave(() => setHoveredIndex("draggingIsOff"), isOver, didDrop);

  const handleItemHovered = useCallback((hoveredItem: ExcelReportColumnWrapper) => {
    if (!canDropRef.current) return;
    const prevIndex = hoveredIndexRef.current;
    const newIndex = hoveredItem.index;
    if (prevIndex !== "draggingIsOff" && prevIndex !== newIndex) {
      setDraggingItemMovedFromInitialPosition(true);
    }
    setHoveredIndex(newIndex);
  }, []);

  const onEndReordering = useCallback(
    (draggingItem: ExcelReportColumnWrapper) => {
      const toIndex = getInsertToIndex(Array.from(listRef.current?.children || []));

      const values = [...fieldsRef.current];
      values.splice(draggingItem.index, 1);
      values.splice(toIndex, 0, draggingItem);
      setTimeout(() => {
        onOrderChanged(values.map((value) => value.column));
      }, 0);
      setHoveredIndex("draggingIsOff");
      setDraggingItemMovedFromInitialPosition(false);
      setFieldItemIndexesForShifting(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    const arr = fieldsRef.current
      .filter((v) => hoveredIndexIsDefined(hoveredIndex) && v.index >= hoveredIndex)
      .map((v) => v.index);
    setFieldItemIndexesForShifting(arr);
  }, [hoveredIndex]);

  useEffect(() => {
    //Dragging is over
    if (!draggingItem) {
      setFieldItemIndexesForShifting(undefined);
      setDraggingItemMovedFromInitialPosition(false);
    }
  }, [draggingItem]);

  return (
    <Grid
      container
      ref={drop}
      flex={1}
      direction={"column"}
      sx={{ minHeight: columns.length * (DEFAULT_AREA_FIELD_ITEM_HEIGHT + 4) }}
    >
      <List ref={listRef} sx={{ p: 0, display: "flex", flexDirection: "column", gap: "5px" }}>
        {fields.map((field) => {
          const addShifting =
            fieldItemIndexesForShifting !== undefined && fieldItemIndexesForShifting.includes(field.index);
          const hideDraggingItem =
            draggingItem && field.index === draggingItem.index && fieldItemIndexesForShifting !== undefined;
          return (
            <SelectedColumnItem
              key={field.column.id}
              item={field}
              canDrop={canDrop}
              addShifting={addShifting}
              hideDraggingItem={hideDraggingItem}
              draggingItemMovedFromInitialPosition={draggingItemMovedFromInitialPosition}
              onItemHovered={handleItemHovered}
              onEndReordering={onEndReordering}
              onRemoveColumn={onRemoveColumn}
            />
          );
        })}
      </List>
    </Grid>
  );
}
