import React from "react";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "../../../store/userSlice";
import { hasPermissionsForAnyCompany } from "../../helpers/accessUtils";

export default function useAccessIsForbidden() {
  const permissions = useSelector(selectUserPermissions);

  const isAccessForbidden = React.useMemo(() => !hasPermissionsForAnyCompany(permissions), [permissions]);

  return isAccessForbidden;
}
