import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ExcelReport, ReportKind, ReportSource } from "../../api/biApi.types";
import DocumentIcon from "../../icons/DocumentIcon";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { useMemo } from "react";
interface Props {
  report: ExcelReport;
  onReportSelected: (report: ExcelReport) => void;
}

export default function AvailableReportItem({ report, onReportSelected }: Props) {
  const theme = useTheme();

  const title = useMemo(() => report.name || report.code, [report.name, report.code]);
  const subTitle = useMemo(() => {
    let text = `${report.reportType}, ${report.reportSource === ReportSource.BC ? "Fund Operations" : "Reporting"}`;

    if (report.reportKind === ReportKind.MasterDataReport) {
      text += ", Master Data";
    }
    return text;
  }, [report.reportKind, report.reportSource, report.reportType]);

  return (
    <Grid
      container
      sx={(theme) => ({
        alignItems: "center",
        gap: 0.8,
        border: "1px solid " + theme.palette.divider,
        borderRadius: "4px",
        px: 1,
        py: 0.8,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        flexWrap: "nowrap",
      })}
      onClick={() => onReportSelected(report)}
    >
      <DocumentIcon
        sx={{
          fill: "none",
          stroke: report.reportSource === ReportSource.BI ? theme.palette.primary.main : theme.palette.text.secondary,
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", minWidth: 0 }}>
        <Typography variant="subtitle2" color={(theme) => theme.palette.text.primary} noWrap>
          {title}
        </Typography>
        <Typography variant="caption" color={(theme) => theme.palette.text.secondary} sx={{ fontSize: 10 }} noWrap>
          {subTitle}
        </Typography>
      </Box>

      <KeyboardArrowRightRoundedIcon fontSize="small" color="action" sx={{ ml: "auto" }} />
    </Grid>
  );
}
