import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { format, isValid } from "date-fns";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import { DatePicker, DatePickerProps, DateValidationError } from "@mui/x-date-pickers";
import { hasTextFieldAndRequired, parseDate, validateDateRange } from "./helper/bcDatePickerHelper";
import { CustomDatePickerFieldProps } from "./bcDatePickerTypes";

interface BcDatePickerProps extends DatePickerProps<Date> {
  required?: boolean;
}

const BcDatePicker = (props: BcDatePickerProps) => {
  return (
    <DatePicker<Date>
      {...props}
      slots={{ field: CustomDatePickerField, openPickerIcon: CalendarTodayRoundedIcon, ...props.slots }}
      slotProps={{
        textField: { required: props.required },
        field: {
          onError: (error, value) => props.onError?.(error as DateValidationError, value),
        },
        openPickerButton: { size: "small" },
      }}
    />
  );
};

const CustomDatePickerField = (props: CustomDatePickerFieldProps) => {
  const {
    label,
    onError,
    value,
    onChange,
    InputProps: { ref, endAdornment } = {},
    inputProps,
    minDate,
    maxDate,
    slotProps,
  } = props;

  const [innerValue, setInnerValue] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const handleOnBlur = (dateStr: string) => {
    if (dateStr === "") {
      if (hasTextFieldAndRequired(slotProps) && slotProps.textField.required === true) {
        setError("Field is required");
        onError?.("invalidDate", null);
        onChange?.(null, { validationError: "invalidDate" });
      } else {
        setError(null);
        onChange?.(null, { validationError: null });
      }
      return;
    }
    const date = parseDate(dateStr);

    if (date === undefined) {
      setError("Invalid date");
      onError?.("invalidDate", null);
      onChange?.(null, { validationError: "invalidDate" });
      return;
    }
    setError(null);
    onChange?.(date, { validationError: null });
  };

  useEffect(() => {
    if (value && isValid(value)) {
      setInnerValue(format(value, "MM/dd/yyyy"));
      setError(null);
      const validationError = validateDateRange(value, minDate, maxDate);
      if (validationError) {
        setError(validationError);
        onError?.(validationError, value);
      }
    }
  }, [value, minDate, maxDate, onError]);

  return (
    <TextField
      fullWidth
      ref={ref}
      label={label}
      inputProps={{ ...inputProps }}
      InputProps={{ endAdornment: endAdornment }}
      value={innerValue}
      onBlur={({ target: { value } }) => handleOnBlur(value)}
      onChange={(e) => setInnerValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          const target = e.target as HTMLInputElement;
          e.preventDefault();
          target.blur();
        }
      }}
      placeholder="MM/DD/YYYY"
      sx={{ flex: 1, ".MuiInputAdornment-root": { ml: "unset" } }}
      size="small"
      error={!!error}
    />
  );
};

export default BcDatePicker;
