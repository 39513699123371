import { Dispatch, SetStateAction, useMemo, useState } from "react";
import axios, { CanceledError } from "axios";
import { ApiError, ApiResponse } from "../api/axiosHelper.types";

interface ResponseData<TData> {
  data?: TData;
  error?: ApiError;
}

interface RequestData<TData> {
  request: () => Promise<ResponseData<TData>>;
  data: TData | undefined;
  error: ApiError | undefined;
  setData: Dispatch<SetStateAction<TData | undefined>>;
  setError: Dispatch<SetStateAction<ApiError | undefined>>;
  isLoading: boolean;
}

export default function useRequest<TData>(apiCall: () => Promise<ApiResponse<TData>>): RequestData<TData> {
  const [data, setData] = useState<TData>();
  const [error, setError] = useState<ApiError>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const request = useMemo(() => {
    return async () => {
      try {
        setIsLoading(true);
        const resp = await apiCall();
        if (resp.success) {
          setData(resp.data);
          return { data: resp.data };
        } else if (resp.error) {
          setError(resp.error);
        } else {
          setError({ message: "Unknown error", code: 0, type: "General" });
        }
      } catch (err) {
        if (err instanceof CanceledError && err.name === "CanceledError") {
          return {};
        }
        let apiError: ApiError;
        if (axios.isAxiosError(err)) {
          const statusCode = err.response?.status;
          const message = `HTTP Error ${statusCode}: ${err.message}`;
          apiError = { message, code: 0, type: "General" };
          setError(apiError);
        } else {
          apiError = { message: "Unknown error", code: 0, type: "General" };
          setError(apiError);
        }
        return { error: apiError };
      } finally {
        setIsLoading(false);
      }
      return {};
    };
  }, [apiCall]);

  return { request, data, error, isLoading, setData, setError };
}
