import { Avatar, SxProps, Theme, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { getInitialsFromUserName } from "../../../utils/stringHelper";

interface Props {
  userName: string;
  avatarImage?: string;
  size?: number;
}
export default function UserAvatar({ userName, avatarImage, size }: Props) {
  const sx: SxProps<Theme> = (theme) => ({
    width: size ? theme.spacing(size) : undefined,
    height: size ? theme.spacing(size) : undefined,
    bgcolor: "#EDF0F2", // TODO define in theme
  });

  if (avatarImage) {
    return <Avatar src={avatarImage} alt={userName} sx={sx} />;
  }

  return (
    <Avatar sx={sx}>
      {userName ? (
        <Typography variant="subtitle1" color="text.secondary">
          {getInitialsFromUserName(userName)}
        </Typography>
      ) : (
        <PersonIcon fontSize="small" color="secondary" />
      )}
    </Avatar>
  );
}
