export function getDateFromValues(filterValues: string[], defaultValues?: string[]) {
  const value = filterValues[0] || defaultValues?.[0] || "";
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
}

export function getDateToValues(filterValues: string[], defaultValues?: string[]) {
  const value = filterValues[1] || defaultValues?.[1] || "";
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
}
