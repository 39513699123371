import { AppBar, Divider, MenuItem, Toolbar, Typography } from "@mui/material";
import AccountMenu from "./AccountMenu";
import MenuLinkItem from "./ToolbarLinks";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../store/userSlice";
import UserInfoMenuItem from "./UserInfoMenuItem";
import HorizontalFill from "../common/HorizontalFill";
import { PageNames } from "../../../types";
import { accessTokenName, refreshTokenName } from "../../../auth/auth";
import ElevationHeader from "./ElevationHeader";
import LogoIcon from "../../../icons/LogoIcon";
import { removeStoredSelectedCompanyCode } from "../utils/storedClient";

export default function Header() {
  const userInfo = useSelector(selectUserInfo);

  return (
    <ElevationHeader>
      <AppBar position="sticky" sx={(theme) => ({ bgcolor: theme.palette.common.black })}>
        <Toolbar sx={{ py: 1.5, px: 2.5 }}>
          <LogoIcon sx={{ width: "110px" }} />
          <HorizontalFill />
          <AccountMenu userName={userInfo?.userName || "[User]"}>
            <UserInfoMenuItem userName={userInfo?.userName || "[User]"} />
            <Divider />
            <MenuItem
              sx={{ display: "none" }}
              onClick={() => {
                localStorage.removeItem(accessTokenName);
              }}
            >
              <Typography variant="body2">Clean Auth Token</Typography>
            </MenuItem>
            <MenuItem
              sx={{ display: "none" }}
              onClick={() => {
                localStorage.removeItem(refreshTokenName);
              }}
            >
              <Typography variant="body2">Clean Refresh Token</Typography>
            </MenuItem>
            <MenuItem sx={{ display: "none" }} onClick={() => removeStoredSelectedCompanyCode()}>
              <Typography variant="body2">Clean Current Selected Client</Typography>
            </MenuItem>
            <MenuLinkItem title="Log out" href={PageNames.Logout} Icon={LogoutIcon} />
          </AccountMenu>
        </Toolbar>
      </AppBar>
    </ElevationHeader>
  );
}
