import axios from "axios";
import RefreshTokenService from "./RefreshTokenService";
import { isAuthTokenExist, isRefreshTokenExist, redirectToAccessForbidden, redirectToRoot } from "../auth/auth";
import { ExtendedAxiosError } from "./axiosHelper.types";

export const setUpAxios = () => {
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    (res) => res,
    async (error) => {
      const axiosError = error as ExtendedAxiosError;
      if (!axiosError.isAxiosError) {
        return Promise.reject(error);
      }

      if (axiosError.response?.status === 403) {
        if (axiosError.config?.renewPermissionsOnAccessForbidden === true) {
          redirectToRoot();
        } else {
          redirectToAccessForbidden();
        }
        return Promise.resolve({ data: undefined });
      }

      if (axiosError.response?.status !== 401 || axiosError.config?.skipAuthRefresh) {
        return Promise.reject(error);
      }

      if (isRefreshTokenExist() && (await RefreshTokenService.do())) {
        return axios.request({ ...error.config, skipAuthRefresh: true });
      }

      redirectToRoot();

      return Promise.resolve();
    }
  );

  axios.interceptors.request.use((request) => {
    if (!isAuthTokenExist() && isRefreshTokenExist() && !request.url?.includes("auth/inlineRefresh")) {
      return RefreshTokenService.do().then(() => request);
    }

    return request;
  });
};
