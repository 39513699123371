import { isBefore, isEqual } from "date-fns";
import { ExcelReportParameter } from "../../../api/biApi.types";
import { XEntriliaReportParameter } from "../../../store/store.types";

export function allRequiredParametersSet(parameters: ExcelReportParameter[], filters: XEntriliaReportParameter[]) {
  const requiredParams = parameters.filter((p) => p.required);
  return requiredParams.every((p) =>
    filters.some((f) => f.id === p.id && f.values.length > 0 && !f.values.some((v) => v === null))
  );
}

export function allDataRangeParametersAreValid(
  parameters: ExcelReportParameter[],
  filters: XEntriliaReportParameter[]
) {
  const dateRangeParameters = parameters.filter((p) => p.type === "daterange");
  if (dateRangeParameters.length === 0) {
    return true;
  }
  return dateRangeParameters.every((p) => {
    const filter = filters.find((f) => f.id === p.id);
    if (filter === undefined) return true;
    return (
      filter.values.length === 2 &&
      filter.values.every((v) => v !== null) &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      validateRangeMinMax(filter.values[0]!, filter.values[1]!)
    );
  });
}

function validateRangeMinMax(from: string, to: string) {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  return isBefore(fromDate, toDate) || isEqual(fromDate, toDate);
}
