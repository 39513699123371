import { Box, IconButton, Tooltip } from "@mui/material";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import { ReactElement } from "react";

interface Props {
  cancellable: boolean;
  onClick: () => void;
}

const StopLoadingReportButton = ({ cancellable, onClick }: Props) => {
  return (
    <StopLoadingReportButtonWrapper useTooltip={!cancellable}>
      <Box component={"span"}>
        <IconButton
          size="small"
          sx={(theme) => ({ p: 0.4, color: theme.palette.secondary.light })}
          disabled={!cancellable}
          onClick={(evt) => {
            evt.stopPropagation();
            onClick();
          }}
        >
          <StopCircleRoundedIcon />
        </IconButton>
      </Box>
    </StopLoadingReportButtonWrapper>
  );
};

export default StopLoadingReportButton;

const StopLoadingReportButtonWrapper = ({ useTooltip, children }: { useTooltip: boolean; children: ReactElement }) =>
  useTooltip ? (
    <Tooltip
      title="At this step you cannot stop the update, please wait for the refresh to complete"
      arrow
      disableInteractive
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
