import { UserPermissionSet } from "../../api/biApi.types";
import { XEntriliaReportDescriptor } from "../../store/store.types";

export const hasAccessToReport = (report: XEntriliaReportDescriptor, userPermissions: UserPermissionSet[]) => {
  return userPermissions.some((p) => p.clientCode === report.clientCode && p.permissions.includes("ViewReports"));
};

export const hasPermissionsForAnyCompany = (userPermissions: UserPermissionSet[]) => {
  return userPermissions.some((p) => p.permissions.includes("ViewReports"));
};

export const hasPermissionsForCompany = (clientCode: string, userPermissions: UserPermissionSet[]) => {
  return userPermissions.some((p) => p.clientCode === clientCode && p.permissions.includes("ViewReports"));
};
