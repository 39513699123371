const StoredSelectedCompanyKey = "XStoredSelectedCompany";

export const setStoredSelectedCompanyCode = (code: string) => {
  localStorage.setItem(StoredSelectedCompanyKey, code);
};

export const getStoredSelectedCompanyCode = () => {
  return localStorage.getItem(StoredSelectedCompanyKey);
};

export const removeStoredSelectedCompanyCode = () => {
  localStorage.removeItem(StoredSelectedCompanyKey);
};
