import { addHours, addSeconds } from "date-fns";
import { getSettings } from "../variables";
import { PageNames } from "../types";
import { RedirectKeys } from "../pages/RedirectKeys";
import { UserInfo } from "../store/store.types";

const entriliaUserInfoName = "x-entrilia-user-info";
export const accessTokenName = "x-entrilia-access-token";
export const refreshTokenName = "x-entrilia-refresh-token";

export const getUserInfo = () => {
  const infoStr = localStorage.getItem(entriliaUserInfoName);
  if (!infoStr) {
    return undefined;
  }
  const info = JSON.parse(infoStr) as UserInfo;
  return info;
};

export const persistUserInfo = (info: UserInfo, idp: string) => {
  localStorage.setItem(entriliaUserInfoName, JSON.stringify(info));
  setAuthToken(info);
  setRefreshToken(info);
  setIdp(idp);
};

export const removeUserInfo = () => {
  localStorage.removeItem(entriliaUserInfoName);
};

const setAuthToken = (userInfo: UserInfo) => {
  localStorage.setItem(accessTokenName, userInfo.accessToken);

  if (userInfo.accessTokenExpiresIn) {
    const expiresOn = addSeconds(new Date(), userInfo.accessTokenExpiresIn);
    localStorage.setItem("accessTokenExpiresOn", expiresOn.getTime().toString());
  } else if (userInfo.accessTokenExpiresOn) {
    localStorage.setItem("accessTokenExpiresOn", userInfo.accessTokenExpiresOn.toString());
  } else {
    localStorage.setItem("accessTokenExpiresOn", addHours(new Date(), 1).getTime().toString());
  }
};

export const getAuthToken = () => {
  const accessToken = localStorage.getItem(accessTokenName);
  if (accessToken) {
    const expiresOn = localStorage.getItem("accessTokenExpiresOn");
    const expired = new Date().getTime() > parseInt(expiresOn || "");
    if (expired) {
      localStorage.removeItem(accessTokenName);
      localStorage.removeItem("accessTokenExpiresOn");
      return undefined;
    }
    return accessToken;
  }
  return undefined;
};

const setRefreshToken = (userInfo: UserInfo) => {
  localStorage.setItem(refreshTokenName, userInfo.refreshToken);
  if (userInfo.refreshTokenExpiresIn) {
    const expiresOn = addSeconds(new Date(), userInfo.refreshTokenExpiresIn);
    localStorage.setItem("refreshTokenExpiresOn", expiresOn.getTime().toString());
  } else {
    localStorage.setItem("refreshTokenExpiresOn", addHours(new Date(), 23).toString());
  }
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem(refreshTokenName);
  if (refreshToken) {
    const expiresOn = localStorage.getItem("refreshTokenExpiresOn");
    const expired = new Date().getTime() > parseInt(expiresOn || "");
    if (expired) {
      localStorage.removeItem(refreshTokenName);
      localStorage.removeItem("refreshTokenExpiresOn");
      return undefined;
    }
    return refreshToken;
  }
  return undefined;
};

const setIdp = (idp: string) => {
  localStorage.setItem("idp", idp);
};

export const getIdp = () => {
  return localStorage.getItem("idp") || "Microsoft";
};

export const isAuthTokenExist = () => {
  const token = getAuthToken();
  return !!token;
};

export const isRefreshTokenExist = () => {
  const token = getRefreshToken();
  return !!token;
};

export const isAccessAndRefreshTokenInvalid = () => {
  return !getAuthToken() && !getRefreshToken();
};

export const isAuthRequired = () => {
  return !isAuthTokenExist() || !isRefreshTokenExist();
};

export function redirectToExternalLoginSignin() {
  const currentUrl = new URL(window.location.href);
  const urlParams = new URLSearchParams();
  urlParams.set("state", JSON.stringify({ origin: currentUrl.origin, originalPath: "/" }));
  urlParams.set("returnType", "code");
  if (currentUrl.searchParams.has(RedirectKeys.Prompt)) {
    urlParams.set(RedirectKeys.Prompt, currentUrl.searchParams.get(RedirectKeys.Prompt) || "");
  }
  window.location.href = `${getSettings().LOGIN_APP_URI}/signin?${urlParams.toString()}`;
}

export function redirectToRoot() {
  const currentUrl = new URL(window.location.href);
  window.location.href = currentUrl.origin;
}

export function redirectToAccessForbidden() {
  const currentUrl = new URL(window.location.href);
  window.location.href = `${currentUrl.origin}/${PageNames.AccessForbidden}`;
}

export async function logout() {
  removeUserInfo();
  cleanUpTokens();
}

export function cleanUpTokens() {
  localStorage.removeItem(accessTokenName);
  localStorage.removeItem(refreshTokenName);
  localStorage.removeItem("accessTokenExpiresOn");
  localStorage.removeItem("refreshTokenExpiresOn");
}

export function cleanUpRefreshToken() {
  localStorage.removeItem(refreshTokenName);
  localStorage.removeItem("refreshTokenExpiresOn");
}
