import { Box } from "@mui/material";

interface Props {
  logoUrl?: string;
}

export default function ClientIcon({ logoUrl }: Props) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width={24} height={24} p={0.3} bgcolor="white">
      <img alt="Client Logo Small" style={{ width: "24px", height: "24px" }} src={logoUrl} />
    </Box>
  );
}
