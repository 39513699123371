import React from "react";
import { ExcelReportParameter, FilterModelType, ReportSource } from "../../api/biApi.types";
import { XEntriliaReportParameter } from "../../store/store.types";
import cloneDeep from "../../utils/cloneDeep";
import isEqual from "../../utils/isEqual";
import { useParameterValues } from "../components/hooks/useParameterValues";
import FilterItemTableView from "./FilterItemTableView";
import { getParameterValue } from "./helpers/parametersHelper";

interface Props {
  info: ExcelReportParameter;
  filterValues: string[];
  filters: XEntriliaReportParameter[];
  clientCode: string;
  onUpdateFilter: (param: ExcelReportParameter, values: string[]) => void;
}

export default function FilterItemTableBySubValues({ info, filterValues, filters, clientCode, onUpdateFilter }: Props) {
  const param = useParameterValues(ReportSource.BC, info.name, clientCode);
  const [selectedValues, setSelectedValues] = React.useState<string[]>(cloneDeep(filterValues));
  const [subValues, setSubValues] = React.useState<(string | null)[]>();

  const subValuesParameter = React.useMemo(() => {
    return filters.find((f) => f.name === info.subValuesParameterNo);
  }, [filters, info.subValuesParameterNo]);

  const filteredValues = React.useMemo(() => {
    if (subValuesParameter === undefined || subValuesParameter.values.length === 0) {
      return param.value?.values;
    }
    return param.value?.values?.filter((p) => p.subValues?.some((sv) => subValuesParameter.values.includes(sv)));
  }, [subValuesParameter, param.value]);

  React.useEffect(() => {
    if (subValuesParameter === undefined || subValuesParameter.values.length === 0) return;
    const copy = cloneDeep(subValuesParameter.values);
    if (filteredValues !== undefined && !isEqual(copy, subValues)) {
      setSelectedValues((prev) => prev.filter((v) => filteredValues?.map((f) => getParameterValue(f)).includes(v)));
      setSubValues(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subValuesParameter]);

  React.useEffect(() => {
    onUpdateFilter(info, selectedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, onUpdateFilter]);

  return (
    <FilterItemTableView
      onApply={setSelectedValues}
      filteredValues={filteredValues}
      info={info}
      selectedValues={selectedValues}
      isLoading={param.loading}
      equalityType={FilterModelType.Equal}
    />
  );
}
