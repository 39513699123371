import { Grid, Typography } from "@mui/material";
import HorizontalFill from "../components/common/HorizontalFill";
import { useSelector } from "react-redux";
import { selectReports } from "../../store/reportsSlice";
import WorkbookReports from "./WorkbookReports";
import DevTools from "../components/DevTools";
import DevPanel from "../components/common/DevPanel";
import { useNavigate } from "react-router-dom";
import { Footer } from "./Footer";
import { PageNames } from "../../types";
import { ReportActions } from "./reports/ReportActions";
import { NoReports } from "./reports/NoReports";

export default function HomePage() {
  const reports = useSelector(selectReports);
  const navigate = useNavigate();
  const navigateToAddReport = () => navigate(PageNames.AddReport);

  return (
    <Grid container sx={{ flex: 1, flexDirection: "column" }}>
      <Grid container sx={{ flex: 1, flexDirection: "column", gap: 0.5, px: 2, py: 1 }}>
        {reports.length === 0 && (
          <>
            <Grid
              container
              sx={{ justifyContent: "center", flex: 1, flexDirection: "column", alignItems: "center", gap: 1 }}
            >
              <NoReports onAddReport={navigateToAddReport} />
            </Grid>
          </>
        )}
        {reports.length > 0 && (
          <>
            <Grid container sx={{ flexDirection: "row", alignItems: "center", gap: 1 }}>
              <Typography variant="subtitle2">Reports</Typography>
              <HorizontalFill />
              <ReportActions onAddReport={navigateToAddReport} />
            </Grid>
            <WorkbookReports />
            <Grid container flex={1}></Grid>
          </>
        )}
        <DevPanel>
          <DevTools />
        </DevPanel>
      </Grid>
      <Footer />
    </Grid>
  );
}
