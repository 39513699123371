import React from "react";
import { defined } from "../utils/typeHelper";
import { ReportServiceReturnType } from "../pages/components/hooks/useReportService";

const ReportServiceContext = React.createContext<ReportServiceReturnType | undefined>(undefined);

export const ReportServiceContextProvider = (props: React.PropsWithChildren<ReportServiceReturnType>) => (
  <ReportServiceContext.Provider value={{ ...props }}>{props.children}</ReportServiceContext.Provider>
);

export const useReportServiceContext = () => {
  const context = React.useContext(ReportServiceContext);
  return defined(context);
};
