import { BiApi } from "../../../api/biApi";
import { ExcelReport, ReportSource } from "../../../api/biApi.types";
import { XEntriliaReportDescriptor, XEntriliaReportParameter } from "../../../store/store.types";
import { ReportRequest } from "../types/reportService.types";
import { generateReportId } from "./storedReportSettings";

export const checkReportStatus = async (
  reportType: ReportSource | string | undefined,
  requestCode: string,
  clientCode: string,
  signal?: AbortSignal
) => {
  try {
    const result = await BiApi.getRequestedReportStatus(reportType, requestCode, clientCode, signal);
    return result;
  } catch {
    return undefined;
  }
};

export const requestReport = async (request: ReportRequest, signal?: AbortSignal): Promise<string | undefined> => {
  if (request.report.reportCode === undefined) return undefined;
  try {
    const result = await BiApi.requestReport(
      request.report.reportCode,
      request.report.reportSource,
      request.report.reportKind,
      request.filters,
      request.withParameters,
      request.selectedColumns,
      request.report.clientCode,
      signal
    );
    if (result.success === true && result.data.reportExportCode !== undefined) {
      return result.data.reportExportCode;
    }
    return undefined;
  } catch {
    return undefined;
  }
};

export const createNewReport = (
  worksheetId: string,
  reportToGenerate: ExcelReport,
  filters: XEntriliaReportParameter[],
  clientCode: string,
  withParameters: boolean,
  selectedColumns: number[] | undefined
): XEntriliaReportDescriptor => {
  return {
    id: generateReportId(),
    worksheetId,
    caption: reportToGenerate.name || reportToGenerate.code,
    reportCode: reportToGenerate.code,
    reportSource: reportToGenerate.reportSource,
    reportKind: reportToGenerate.reportKind,
    filters,
    clientCode: clientCode,
    reportType: reportToGenerate.reportType,
    withParameters: withParameters,
    address: "",
    originalAddress: "",
    worksheetName: "",
    changedAt: new Date().getTime(),
    selectedColumns,
  };
};
