import { Avatar, Grid, IconButton, Stack } from "@mui/material";
import React from "react";
import { selectReportsDeleting, selectReportsLoading } from "../../store/reportsSlice";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useSelector } from "react-redux";
import WorkbookReportItemContent from "./WorkbookReportItemContent";
import WorkbookReportItemLoadingContent from "./WorkbookReportItemLoadingContent";
import FiltersPanel from "./filtersPanel/FiltersPanel";
import { EntriliaReport } from "../components/EntriliaReport";
import WorkbookReportItemDeletingContent from "./WorkbookReportItemDeletingContent";
import ClientIcon from "../selectClient/ClientIcon";
import { selectClients, selectUserPermissions } from "../../store/userSlice";
import { hasAccessToReport } from "../helpers/accessUtils";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { XEntriliaReportDescriptor, StepEnum } from "../../store/store.types";

interface Props {
  report: XEntriliaReportDescriptor;
  activeWorksheet?: string;
}

export default function WorkbookReportItem({ report, activeWorksheet }: Props) {
  const clients = useSelector(selectClients);
  const reportsLoading = useSelector(selectReportsLoading);
  const reportsDeleting = useSelector(selectReportsDeleting);
  const permissions = useSelector(selectUserPermissions);

  const [expanded, setExpanded] = React.useState(false);
  const entriliaReport = React.useMemo(() => new EntriliaReport(report), [report]);

  const reportCompanyLogo = clients.find((client) => client.clientCode === report.clientCode)?.branding.logoMarkUrl;

  const reportLoading = React.useMemo(() => reportsLoading.find((rl) => rl.id === report.id), [reportsLoading, report]);
  const isReportLoading = React.useMemo(
    () => !!reportLoading && (reportLoading.loading || reportLoading.step === StepEnum.Done),
    [reportLoading]
  );

  const deletingReport = React.useMemo(
    () => reportsDeleting.find((rl) => rl.id === report.id),
    [reportsDeleting, report]
  );

  const isReadonlyReport = !hasAccessToReport(report, permissions);
  const isError = React.useMemo(() => !!reportLoading && reportLoading.error, [reportLoading]);

  return (
    <Grid
      container
      sx={(t) => ({
        border: "1px solid " + t.palette.divider,
        borderRadius: "4px",
        borderColor: activeWorksheet === report.worksheetId ? t.palette.primary.main : t.palette.divider,
        bgcolor: t.palette.background.paper,
      })}
    >
      <Grid
        container
        sx={(t) => ({
          alignItems: "center",
          borderRadius: "4px",
          p: 0.85,
          "&:hover": {
            cursor: "pointer",
            bgcolor: t.palette.action.hover,
          },
          gap: 0.5,
        })}
        onClick={() => entriliaReport.activateWorksheet()}
      >
        <IconButton
          size="small"
          sx={(theme) => ({ p: 0.4, color: theme.palette.secondary.light })}
          onClick={(evt) => {
            evt.stopPropagation();
            setExpanded(!expanded);
          }}
          disabled={deletingReport !== undefined}
        >
          {expanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />}
        </IconButton>
        <Stack direction={"row"} flex={1} alignItems={"center"} spacing={1} minWidth={0}>
          {isReadonlyReport ? (
            <Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: "#EEEFF0" }}>
              <LockOutlinedIcon color="disabled" fontSize="small" />
            </Avatar>
          ) : (
            <ClientIcon logoUrl={reportCompanyLogo} />
          )}

          {reportLoading !== undefined && isReportLoading && !isError && (
            <WorkbookReportItemLoadingContent report={report} loadingReport={reportLoading} />
          )}
          {deletingReport !== undefined && !isError && <WorkbookReportItemDeletingContent report={report} />}
          {((!isReportLoading && deletingReport === undefined) || isError) && (
            <WorkbookReportItemContent report={report} error={reportLoading?.error} disabled={isReadonlyReport} />
          )}
        </Stack>
      </Grid>
      <FiltersPanel
        expanded={expanded}
        report={report}
        filters={report.filters || []}
        isReadonlyReport={isReadonlyReport}
      />
    </Grid>
  );
}
