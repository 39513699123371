import React from "react";
import InlineLoader from "../pages/components/inlineLoader/InlineLoader";

export default function ProceedAuthentication() {
  const urlParams = React.useMemo(() => {
    const prms = new URLSearchParams(window.location.search);
    const code = prms.get("code");
    const idp = prms.get("idp") || "Microsoft";
    return { code, idp };
  }, []);

  React.useEffect(() => {
    if (urlParams.code === null) {
      Office.context.ui.messageParent(JSON.stringify({ type: "x-entrilia-auth-error", error: "No code provided" }));
    } else {
      Office.context.ui.messageParent(JSON.stringify({ type: "x-entrilia-auth", ...urlParams }));
    }
  }, [urlParams]);

  return <InlineLoader text="Authenticating..." />;
}
