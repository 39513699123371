import React from "react";
import { ExcelReportParameter } from "../../api/biApi.types";
import { Grid, Switch } from "@mui/material";
import FieldLabel from "../components/common/FieldLabel";

interface Props {
  info: ExcelReportParameter;
  filterValues: string[];
  onUpdateFilter: (param: ExcelReportParameter, values: string[]) => void;
}
export function FilterItemBoolean({ info, filterValues, onUpdateFilter }: Props) {
  const [value, setValue] = React.useState<boolean | undefined>(() => getBooleanFromValues(filterValues));

  React.useEffect(() => {
    onUpdateFilter(info, [value?.toString() || ""]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, onUpdateFilter]);

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <Grid container sx={{ flex: 1 }}>
        <Switch size="small" onChange={(_, value) => setValue(value)}></Switch>
      </Grid>
    </>
  );
}

function getBooleanFromValues(filterValues: string[]) {
  if (filterValues.length === 0) {
    return undefined;
  }

  return filterValues[0] === "true";
}
