import React from "react";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import useAvailableReports from "../components/hooks/useAvailableReports";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveWorksheetId } from "../../store/workbookSlice";
import SetParametersStep from "../parameters/SetParametersStep";
import { removeReportFromLoading, selectReports, selectReportsLoading, updateTrigger } from "../../store/reportsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import ReportGeneration from "../components/ReportGeneration";
import { ExcelReport } from "../../api/biApi.types";
import { XEntriliaReportParameter } from "../../store/store.types";

export default function EditReportPage() {
  const dispatch = useDispatch();
  const { reportId } = useParams();
  const activeWorksheetId = useSelector(selectActiveWorksheetId);
  const storedReports = useSelector(selectReports);

  const navigate = useNavigate();
  const { reloadReport, cancelRequest } = useReportServiceContext();

  const [filters, setFilters] = React.useState<XEntriliaReportParameter[]>([]);
  const [generateReport, setGenerateReport] = React.useState(false);
  const [reportToEdit, setReportToEdit] = React.useState<ExcelReport | undefined>();
  const [withParameters, setWithParameters] = React.useState(false);
  const [selectedColumns, setSelectedColumns] = React.useState<number[]>([]);
  const reportsLoading = useSelector(selectReportsLoading);

  const loadingReport = React.useMemo(() => {
    return reportsLoading?.find((lr) => lr.id === reportId);
  }, [reportId, reportsLoading]);

  const storedReport = React.useMemo(() => storedReports.find((rf) => rf.id === reportId), [storedReports, reportId]);
  const { isLoading, availableCompanyReports } = useAvailableReports({ clientCode: storedReport?.clientCode });

  const requestReportExport = React.useCallback(async () => {
    if (reportId && storedReport) {
      await reloadReport(storedReport, filters, withParameters, selectedColumns);
    }
  }, [reportId, storedReport, reloadReport, filters, withParameters, selectedColumns]);

  const retryToLoadReport = React.useCallback(async () => {
    await requestReportExport();
  }, [requestReportExport]);

  const goHome = React.useCallback(() => {
    if (reportId !== undefined) {
      dispatch(removeReportFromLoading(reportId));
    }
    dispatch(updateTrigger());
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  React.useEffect(() => {
    if (availableCompanyReports) {
      setReportToEdit(availableCompanyReports.find((ar) => ar.code === storedReport?.reportCode));
      setFilters(storedReport?.filters || []);
      setWithParameters(storedReport?.withParameters || false);
      setSelectedColumns(storedReport?.selectedColumns || []);
    }
  }, [availableCompanyReports, storedReport]);

  if (isLoading) {
    return <InlineLoader text="Preparing report for editing..." />;
  }

  if (!reportToEdit || !activeWorksheetId || !storedReport?.clientCode) {
    return null;
  }

  return (
    <>
      {!generateReport ? (
        <SetParametersStep
          report={reportToEdit}
          originalFilters={filters}
          submitButtonText="Update report"
          exportWithParameters={withParameters}
          clientCode={storedReport.clientCode}
          submitButtonDisabled={loadingReport !== undefined}
          selectedColumns={selectedColumns}
          onChangeExportWithParameters={setWithParameters}
          onColumnsSelected={setSelectedColumns}
          onBack={() => navigate("/")}
          onContinue={(newFilters) => {
            setFilters(newFilters);
            setGenerateReport(true);
          }}
        />
      ) : (
        <ReportGeneration
          handleReport={requestReportExport}
          refreshReport={retryToLoadReport}
          goHome={goHome}
          cancelRequest={() => {
            setGenerateReport(false);
            cancelRequest(reportToEdit.code, storedReport.worksheetId);
          }}
          reportId={reportId}
          loadingReport={loadingReport}
        />
      )}
    </>
  );
}
