import { BcParameterDictionary, ExcelReportParameter, FilterModelType } from "../../api/biApi.types";
import { useParameterValues } from "../components/hooks/useParameterValues";
import React from "react";
import cloneDeep from "../../utils/cloneDeep";
import FilterItemTableView from "./FilterItemTableView";
import { getHeaderValues } from "./utils/parametersHelper";

interface Props {
  info: ExcelReportParameter;
  filterValues: string[];
  equalityType?: FilterModelType;
  clientCode: string;
  onUpdateFilter: (param: ExcelReportParameter, values: string[], equalityType?: FilterModelType) => void;
}

export function FilterItemTable({ info, filterValues, onUpdateFilter, equalityType, clientCode }: Props) {
  const param = useParameterValues(info.reportSource, info.name, clientCode);
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const [equality, setEquality] = React.useState(equalityType ?? info.equalityType);

  React.useEffect(() => {
    onUpdateFilter(info, selectedValues, equality);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, onUpdateFilter, equality]);

  React.useEffect(() => {
    if (param.loaded) {
      setSelectedValues(
        filterUnavailableValues(getInitialValues(info.defaultValues || [], filterValues), param.value?.values || [])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.value]);

  return (
    <FilterItemTableView
      onApply={(values, equality) => {
        setSelectedValues(values);
        setEquality(equality);
      }}
      filteredValues={param.value?.values}
      info={info}
      selectedValues={selectedValues}
      isLoading={param.loading}
      equalityType={equality}
    />
  );
}

function getInitialValues(defaultValues: string[], filterValues: string[]) {
  if (filterValues.length === 0 && defaultValues && defaultValues?.length > 0) {
    return cloneDeep(defaultValues);
  }
  return cloneDeep(filterValues);
}

function filterUnavailableValues(selectedValues: string[], availableParameters: BcParameterDictionary[]): string[] {
  const { valueHeaderName } = getHeaderValues(availableParameters);

  return selectedValues.filter((v) => availableParameters.some((av) => av[valueHeaderName] === v));
}
