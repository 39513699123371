import { Grid, Stack, Typography, LinearProgress, IconButton } from "@mui/material";
import React from "react";
import { removeReportFromLoading } from "../../store/reportsSlice";
import { useDispatch } from "react-redux";
import CheckCircle from "../../icons/CheckCircle";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import StopLoadingReportButton from "./StopLoadingReportButton";
import { XEntriliaReportDescriptor, XEntriliaReportLoading, StepEnum } from "../../store/store.types";

interface Props {
  report: XEntriliaReportDescriptor;
  loadingReport: XEntriliaReportLoading;
}

const loadingStepsMap: ReadonlyMap<StepEnum | undefined, number> = new Map([
  [StepEnum.Pending, 0],
  [StepEnum.RequestReportToGenerate, 25],
  [StepEnum.WaitingReportToBeGenerated, 50],
  [StepEnum.FetchGeneratedReport, 75],
  [StepEnum.PopulateReportToWorksheet, 100],
  [StepEnum.Done, 100],
]);

function getLoadingStepValue(step: StepEnum | undefined): number {
  return loadingStepsMap.get(step) || 0;
}

export default function WorkbookReportItemLoadingContent({ report, loadingReport }: Props) {
  const dispatch = useDispatch();
  const [progress, setProgress] = React.useState(0);
  const { cancelRequest } = useReportServiceContext();

  const isRefreshCompleted = loadingReport.step === StepEnum.Done;
  const showStopButton = loadingReport.loading === true && !isRefreshCompleted;
  const cancellable = loadingReport?.cancellable === true;

  React.useEffect(() => {
    if (loadingReport.loading) {
      setProgress(getLoadingStepValue(loadingReport.step));
    }
  }, [loadingReport]);

  return (
    <Grid
      container
      sx={{ flexDirection: "row", flex: 1, gap: 1, alignItems: "center", flexWrap: "nowrap", minWidth: 0 }}
    >
      <Stack direction="column" flex={1} minWidth={0}>
        <Typography sx={{ flex: 1, fontSize: "12px", fontWeight: 500, lineHeight: "14.4px" }}>
          {report.caption}
        </Typography>
        {!isRefreshCompleted && (
          <Grid container sx={{ flex: 1, flexDirection: "row", alignItems: "center", gap: 2 }}>
            <LinearProgress variant="determinate" value={progress} sx={{ flex: 1, borderRadius: "3px" }} />
            <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
              {loadingReport.step}/4
            </Typography>
          </Grid>
        )}
        {!isRefreshCompleted && (
          <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.text.secondary })} noWrap>
            Step {loadingReport.step} - {loadingReport.stepDescription}
          </Typography>
        )}
        {isRefreshCompleted && (
          <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.primary.main })}>Completed</Typography>
        )}
      </Stack>
      {showStopButton && (
        <StopLoadingReportButton
          cancellable={cancellable}
          onClick={() => {
            cancelRequest(report.reportCode, report.worksheetId);
            dispatch(removeReportFromLoading(report.id));
          }}
        />
      )}
      {isRefreshCompleted && (
        <IconButton size="small" sx={{ p: 0.4, mr: 1 }}>
          <CheckCircle />
        </IconButton>
      )}
    </Grid>
  );
}
