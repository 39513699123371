import { createSelector, createSlice } from "@reduxjs/toolkit";
import { BcParameterDictionary } from "../api/biApi.types";
import cloneDeep from "../utils/cloneDeep";

export type ReportParameter = {
  name: string;
  values: BcParameterDictionary[];
};

export type ParametersConfiguration = {
  items: ReportParameter[];
};

const initialState: ParametersConfiguration = {
  items: [],
};

const parametersSlice = createSlice({
  name: "parametersConfiguration",
  initialState,
  reducers: {
    setParameter(state, action: { payload: ReportParameter }) {
      const index = state.items.findIndex((p) => p.name === action.payload.name);
      if (index > -1) {
        state.items[index] = cloneDeep(action.payload);
      } else {
        state.items.push(cloneDeep(action.payload));
      }
    },
  },
});

export const { setParameter } = parametersSlice.actions;

export const selectParameter = createSelector(
  [(state: { parameters: ParametersConfiguration }) => state.parameters.items, (_, name: string) => name],
  (items, name) => items.find((p) => p.name === name)
);

export default parametersSlice.reducer;
