export function handleRedirectUrlState(): boolean {
  const searchParams = new URLSearchParams(window.location.search);
  const originUrl = new URL(window.location.href);

  const state = searchParams.get("state");
  const code = searchParams.get("code");
  const idp = searchParams.get("idp");
  if (state != null && code != null) {
    try {
      const stateObj = JSON.parse(state);
      const callbackOriginalPath = stateObj["originalPath"];
      const callbackOrigin = stateObj["origin"];

      //do not proceed  redirect if received origin param is not the same as current origin
      if (callbackOriginalPath !== undefined && callbackOrigin !== undefined && originUrl.origin === callbackOrigin) {
        window.location.replace(`${originUrl.origin}/oauth_signin?code=${code}&idp=${idp}`);
        return true;
      }
    } catch {
      //do nothing
    }
  }
  return false;
}
