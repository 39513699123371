import { Grid, Typography } from "@mui/material";
import PoweredByLogoIcon from "../../icons/PoweredByLogoIcon";

export function Footer() {
  return (
    <Grid
      container
      sx={(theme) => ({
        position: "sticky",
        width: "100%",
        bottom: 0,
        borderTop: "1px solid " + theme.palette.divider,
        alignItems: "center",
        gap: 1,
        px: 2,
        py: 0.375, //To prevent the element flicking, the output css value has to be w/o a floating point.
        flexDirection: "row",
        bgcolor: theme.palette.background.paper,
      })}
    >
      <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.text.secondary })}>Powered by</Typography>
      <PoweredByLogoIcon />
    </Grid>
  );
}
