import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ width: 62, height: 59 }} viewBox="0 0 62 59" {...props}>
      <path
        d="M0 0V52.5H35.6C33 51.5 30.7 49.8 29.1 47.5H5V5H47.5V24.8C49.4 25.4 51.1 26.4 52.5 27.7V0H0Z"
        fill="#C7DBE5"
      />
      <path d="M39.5001 10.3H13.1001V15.3H39.5001V10.3Z" fill="#C7DBE5" />
      <path
        d="M43.3002 58.3C33.0002 58.3 24.7002 50 24.7002 39.7C24.7002 29.5 33.0002 21.1 43.3002 21.1C53.5002 21.1 61.9002 29.4 61.9002 39.7C61.9002 50 53.5002 58.3 43.3002 58.3ZM43.3002 26.1C35.8002 26.1 29.7002 32.2 29.7002 39.7C29.7002 47.2 35.8002 53.3 43.3002 53.3C50.8002 53.3 56.9002 47.2 56.9002 39.7C56.9002 32.2 50.8002 26.1 43.3002 26.1Z"
        fill="#C7DBE5"
      />
      <path
        d="M47.5002 21.6C46.1002 21.3 44.7002 21.1 43.3002 21.1C33.1002 21.1 24.7002 29.4 24.7002 39.7C24.7002 42.5 25.3002 45.1 26.4002 47.5C27.3002 49.3 28.4002 51 29.8002 52.5H38.6002C36.0002 51.5 33.7002 49.8 32.1002 47.5C30.5002 45.3 29.7002 42.6 29.7002 39.7C29.7002 32.2 35.8002 26.1 43.3002 26.1C44.8002 26.1 46.2002 26.3 47.5002 26.8C49.4002 27.4 51.1002 28.4 52.5002 29.7V23.5C50.9002 22.7 49.3002 22 47.5002 21.6Z"
        fill="#C7DBE5"
      />
      <path d="M46.8125 32.6314L36.1353 43.3087L39.6708 46.8442L50.348 36.1669L46.8125 32.6314Z" fill="#F44336" />
      <path d="M39.7416 32.6342L36.2061 36.1697L46.8833 46.8469L50.4188 43.3114L39.7416 32.6342Z" fill="#F44336" />
    </SvgIcon>
  );
}
