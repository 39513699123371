import { Grid, Stack, Typography, IconButton, ListItemIcon, Menu, MenuItem, Button, Tooltip } from "@mui/material";
import React from "react";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { addReportToDeleting, selectReportsLoading, updateTrigger } from "../../store/reportsSlice";
import HorizontalFill from "../components/common/HorizontalFill";
import { useDispatch, useSelector } from "react-redux";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import { deleteShapes, deleteTables, removeNamedRange } from "../components/DevTools";
import { useNavigate } from "react-router-dom";
import { PageNames } from "../../types";
import { getStoredReportSettings, storeReportSettings } from "../components/utils/storedReportSettings";
import { getUpdatedAtText } from "./filtersPanel/conditionBlock.helper";
import { XEntriliaReportDescriptor } from "../../store/store.types";

interface Props {
  report: XEntriliaReportDescriptor;
  error?: string;
  disabled: boolean;
}

export default function WorkbookReportItemContent({ report, error, disabled }: Props) {
  const dispatch = useDispatch();
  const { refreshReport } = useReportServiceContext();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const updatedAtText = React.useMemo(() => {
    if (report.changedAt === undefined) return "Unknown";
    return new Date(report.changedAt).toLocaleString();
  }, [report.changedAt]);

  const handleRefreshReport = React.useCallback(() => {
    if (report !== undefined) refreshReport(report.id);
  }, [refreshReport, report]);

  const handleDeleteReport = React.useCallback(() => {
    if (report?.worksheetId === undefined) return;
    Excel.run(async (context) => {
      dispatch(addReportToDeleting(report));
      await removeNamedRange(context, report.worksheetId);
      await removeReportSettings(context, report.id);
      const sheet = context.workbook.worksheets.getItem(report.worksheetId);
      if (sheet !== undefined) {
        const range = sheet.getRange(report.address);
        await deleteTables(sheet, context);
        await deleteShapes(sheet, context);
        range.unmerge();
        range.clear("All");
      }
      dispatch(updateTrigger());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.address, report.worksheetId]);

  return (
    <Grid
      container
      flexDirection={"row"}
      flex={1}
      minWidth={0}
      sx={(t) => ({
        color: disabled ? t.palette.text.disabled : t.palette.text.primary,
      })}
    >
      <Stack flex={1} gap={0.25} minWidth={0}>
        <Stack direction={"row"} flex={1} gap={1} flexWrap={"nowrap"}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "14.4px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
            }}
          >
            {report.caption}
          </Typography>
        </Stack>
        {!error && (
          <Stack
            direction={"row"}
            flex={1}
            gap={1}
            flexWrap={"nowrap"}
            sx={(t) => ({
              color: disabled ? t.palette.text.disabled : t.palette.text.secondary,
            })}
          >
            <Tooltip
              title={getUpdatedAtText(report.changedAt, report.changedBy)}
              arrow
              sx={{ maxWidth: 175 }}
              disableInteractive
            >
              <Typography sx={{ fontSize: "10px", lineHeight: "12px" }} noWrap>
                {updatedAtText}
              </Typography>
            </Tooltip>
            <HorizontalFill />
            <Typography sx={{ fontSize: "10px", lineHeight: "12px" }} noWrap>
              {report.worksheetName}
            </Typography>
          </Stack>
        )}
        {error && (
          <Typography color="error" sx={{ fontSize: "10px" }}>
            {error}
          </Typography>
        )}
      </Stack>
      {error !== undefined && (
        <Button
          size="small"
          color="error"
          variant="text"
          sx={{ fontSize: "12px" }}
          onClick={(evt) => {
            evt.stopPropagation();
            handleRefreshReport();
          }}
        >
          Try again
        </Button>
      )}
      <IconButton
        size="small"
        sx={(theme) => ({ p: 0.4, color: theme.palette.secondary.light })}
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
          evt.stopPropagation();
        }}
        disabled={disabled}
      >
        <MoreVertRoundedIcon fontSize="small" />
      </IconButton>
      {anchorEl !== null && (
        <ReportMenu
          anchorEl={anchorEl}
          activeReport={report}
          handleRefreshReport={handleRefreshReport}
          handleDeleteReport={handleDeleteReport}
          onClose={() => setAnchorEl(null)}
        />
      )}
    </Grid>
  );
}

interface MenuProps {
  anchorEl: HTMLElement | null;
  activeReport: XEntriliaReportDescriptor | undefined;
  handleRefreshReport: () => void;
  handleDeleteReport: () => void;
  onClose: () => void;
}
function ReportMenu({ anchorEl, activeReport, handleRefreshReport, handleDeleteReport, onClose }: MenuProps) {
  const loadingReports = useSelector(selectReportsLoading);
  const navigate = useNavigate();
  const navigateToEditReport = () => navigate(`${PageNames.EditReport}/${activeReport?.id}`);

  const canRefreshCurrentReport = React.useMemo(() => {
    const reportLoading = loadingReports.find((lr) => lr.id === activeReport?.id);
    return reportLoading === undefined || !reportLoading.loading || !!reportLoading.error;
  }, [activeReport, loadingReports]);

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
      <MenuItem
        sx={{ minHeight: "auto" }}
        disabled={!canRefreshCurrentReport}
        onClick={() => {
          handleRefreshReport();
          onClose();
        }}
      >
        <ListItemIcon>
          <CachedRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Quick Refresh
        </Typography>
      </MenuItem>
      <MenuItem sx={{ minHeight: "auto" }} disabled={!canRefreshCurrentReport} onClick={navigateToEditReport}>
        <ListItemIcon>
          <TuneRoundedIcon fontSize="small" sx={(theme) => ({ color: theme.palette.secondary.light })} />
        </ListItemIcon>
        <Typography variant="body2" color={(theme) => theme.palette.text.primary}>
          Refresh with new Filters
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ minHeight: "auto" }}
        onClick={() => {
          handleDeleteReport();
          onClose();
        }}
      >
        <ListItemIcon>
          <DeleteOutlineIcon fontSize="small" color="error" />
        </ListItemIcon>
        <Typography variant="body2" color="error">
          Delete Report
        </Typography>
      </MenuItem>
    </Menu>
  );
}

async function removeReportSettings(context: Excel.RequestContext, reportId: string) {
  const storedReportSettings = await getStoredReportSettings(context);
  await storeReportSettings(
    context,
    storedReportSettings.filter((s) => s.id !== reportId)
  );
}
