import { Grid, GridProps, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";
import CoverImage from "../../../images/bg_img.svg";

interface Props {
  sx?: SxProps;
  containerProps: GridProps;
}

const infoPageStyle = (theme: Theme): SxProps => ({
  height: "100%",
  background: `url(${CoverImage}) no-repeat center center fixed`,
  backgroundSize: "cover",
  backgroundColor: theme.palette.background.default,
});

const StyledPage = ({ sx, containerProps, children }: PropsWithChildren<Props>) => (
  <Grid container sx={(theme) => ({ ...infoPageStyle(theme), ...sx })} {...containerProps}>
    {children}
  </Grid>
);

export default StyledPage;
