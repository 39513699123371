import { Navigate, Outlet } from "react-router-dom";
import { isAuthTokenExist, isRefreshTokenExist } from "./auth";
import { PageNames } from "../types";

export function AppContainer() {
  const isAuthExist = isAuthTokenExist();
  const isRefreshToken = isRefreshTokenExist();

  if (!isAuthExist && !isRefreshToken) return <Navigate to={PageNames.SignIn} />;
  if (!isAuthExist && isRefreshToken) return <Navigate to={PageNames.DoSignIn} />;

  return <Outlet />;
}
