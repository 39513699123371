export const generateGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function generateStringId() {
  return Math.random().toString(36);
}

export const formatDateUs = (date: Date) => {
  return new Intl.DateTimeFormat("en-US").format(date);
};

export const formatDate = (date: unknown) => {
  if (date instanceof Date && !isNaN(date.getTime())) return formatDateUs(date);
  const concretDate = new Date(date as string);
  if (!isNaN(concretDate.getTime())) return formatDateUs(concretDate);
  return "";
};

export const parseDate = (dateStr: string) => {
  return new Date(dateStr);
};

export const getExcelDateFormatFromLocale = (locale: string) => {
  try {
    const isValidLocale = Intl.DateTimeFormat.supportedLocalesOf(locale).length > 0;
    if (!isValidLocale) {
      return "";
    }

    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formatter = new Intl.DateTimeFormat(locale, options);

    const parts = formatter.formatToParts(new Date());

    let format = "";
    parts.forEach((part) => {
      switch (part.type) {
        case "day":
          format += "dd";
          break;
        case "month":
          format += "mm";
          break;
        case "year":
          format += "yyyy";
          break;
        default:
          format += part.value;
          break;
      }
    });

    return format;
  } catch {
    return "";
  }
};
