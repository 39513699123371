import { TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Subject, debounceTime } from "rxjs";

interface Props {
  placeholder?: string;
  autoFocus?: boolean;
  onSearch?: (text: string) => void;
}

export default function SearchField({ autoFocus, onSearch, placeholder }: Props) {
  const [searchFieldValue, setSearchFieldValue] = useState("");

  const searchSubject = useMemo(() => new Subject<string>(), []);

  useEffect(() => {
    const subscription = searchSubject.pipe(debounceTime(500)).subscribe(onSearch);
    return () => subscription.unsubscribe();
  }, [searchSubject, onSearch]);

  return (
    <TextField
      placeholder={placeholder}
      autoFocus={autoFocus}
      size="small"
      sx={(t) => ({
        flex: 1,
        color: t.palette.secondary.light,
        backgroundColor: "#FAFAFA",
        borderRadius: "4px",
        pl: 0,
        fieldset: {
          borderColor: t.palette.divider,
        },
        "svg:nth-of-type(1)": {
          mr: 0.5,
        },
        svg: {
          color: t.palette.secondary.light,
        },
      })}
      InputProps={{
        sx: {
          pl: 1,
        },
        value: searchFieldValue,
        onChange: (evt) => {
          setSearchFieldValue(evt.target.value);
          searchSubject.next(evt.target.value);
        },
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: searchFieldValue && (
          <CloseIcon
            onClick={() => {
              setSearchFieldValue("");
              searchSubject.next("");
            }}
            fontSize="small"
            cursor="pointer"
          />
        ),
      }}
    />
  );
}
