import { ExcelReport } from "../../api/biApi.types";
import { ReportsGroup } from "./AvailableReportGroup";

const DEFAULT_GROUP_NAME = "Miscellaneous";

export const groupReports = (reports: ExcelReport[]) => {
  const result: ReportsGroup[] = [];
  reports.forEach((ar) => {
    const group = result.find((g) => g.name === (ar.level || DEFAULT_GROUP_NAME));
    if (group) {
      group.reports.push(ar);
    } else {
      result.push({ name: ar.level || DEFAULT_GROUP_NAME, reports: [ar] });
    }
  });
  //sort groups by name
  result.sort((a, b) => a.name.localeCompare(b.name));
  return result;
};
