import { XEntriliaReportDescriptor } from "../../store/store.types";

export class EntriliaReport {
  constructor(public descriptor: XEntriliaReportDescriptor) {}

  public async activateWorksheet(): Promise<void> {
    await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getItem(this.descriptor.worksheetId);
      worksheet.activate();
    });
  }

  public async highlight(): Promise<void> {
    await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getItem(this.descriptor.worksheetId);
      worksheet.getRange(this.descriptor.id).select();
    });
  }
}
